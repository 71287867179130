<template>
  <v-card class="card-container" elevation="0">
    <section class="skeleton__loader" v-if="accountDetails.isCardLoading">
      <avatar-skeleton class="avatar__skeleton"></avatar-skeleton>
      <line-skeleton height="13" :width="getSkeletonWidth" class="line__skeleton--1"></line-skeleton>
      <line-skeleton height="13" class="line__skeleton--2"></line-skeleton>
    </section>
    <template v-else>
      <img :src="accountDetails.img" alt="" class="card-icon"/>
      <section class="card-details"
               :class="{'w-49':!isMerchantOnMerchantQuest,
      'refer__details': isMerchantOnMerchantQuest && isSmallMobile}">
        <div class="title-group">
          <p class="card-title">{{ accountDetails.title }}</p>
          <v-tooltip right color="#3B77D1">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" right class="tooltip-icon">
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>{{ accountDetails.tooltip }}</span>
          </v-tooltip>
        </div>
        <p class="card-info">
          {{ accountDetails.isDataUnavailable ? 'N/A' : accountDetails.info }}
        </p>
      </section>
      <p class="hide-item"
        :class="{'refer-link':!isMerchantOnMerchantQuest}"
        @click="$router.push('/refer')">Refer Now</p>
    </template>
  </v-card>
</template>

<script>
import LineSkeleton from "cpp-js-util/components/common/skeletonLoader/lineSkeleton";
import AvatarSkeleton from "cpp-js-util/components/common/skeletonLoader/avatarSkeleton";

export default {
  name: "QuestAndReferredCard",
  props: ["accountDetails", "isMerchantOnMerchantQuest"],
  components: {
    LineSkeleton,
    AvatarSkeleton
  },
  computed: {
    isSmallMobile() {
      return this.$vuetify.breakpoint.width < 360;
    },
    getSkeletonWidth() {
      return this.isMerchantOnMerchantQuest ? "100" : "65"
    }
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0 !important;
}

/*loader start */
.skeleton__loader {
  width: 100%;
  display: grid;
  grid-template-columns: 46px 1fr;
  grid-template-areas: 
  'avatar line1'
  'avatar .'
  'avatar line2';
  column-gap: 15px;
  row-gap: 12px;
}

.avatar__skeleton {
  grid-area: avatar;
}

.line__skeleton--1 {
  grid-area: line1;
}

.line__skeleton--2 {
  grid-area: line2;
}

/*loader end */

.card-container >>> .v-progress-linear--absolute {
  top: 0 !important;
}

.hide-item {
  display: none;
}

.tooltip-icon {
  font-size: 22px !important;
}

.card-icon {
  display: inline-block;
  height: 48px;
  width: 48px;
}

.card-details {
  width: 62%;
}

.card-title {
  display: inline-block;
  color: #777777;
  font-size: clamp(14px, calc(12px + 0.462vw), 18px);
  font-weight: 500;
  margin-bottom: 12px !important;

}

.card-info {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: clamp(16px, calc(12px + 0.8vw), 19px);
  color: #464646;
  line-height: 23px;
}

.tooltip-icon {
  margin-top: -4px;
}

.refer-link {
  display: block;
  color: #e2136e;
  font-size: clamp(14px, calc(12px + 0.32vw), 16px);
  font-weight: 400;
  text-decoration: underline;
  align-self: flex-end;
  text-decoration-color: #ff74b1;
  text-decoration-thickness: 2px;
  padding-bottom: 1px;
}

.refer-link:hover {
  cursor: pointer;
}

.w-49 {
  width: 49%;
}

@media screen and (max-width: 1295px) {
  .card-icon {
    height: 44px;
    width: 44px;
  }

  .card-title {
    font-size: clamp(14px, calc(12px + 0.334vw), 18px);
  }

  .card-info {
    font-size: clamp(18px, calc(12px + 0.462vw), 19px);
  }

  .refer-link {
    font-size: clamp(14px, calc(12px + 0.16vw), 16px);
    padding-left: 5px;
  }

}

@media screen and (max-width: 1277px) {
  .card-info {
    font-size: 17px;
  }
}

@media screen and (max-width: 1180px) {
  .card-title {
    font-size: 14px;
  }

  .card-info {
    font-size: 15px;
  }

  .card-icon {
    height: 32px;
    width: 32px;
  }

  .card-details {
    width: 64%;
  }

  .tooltip-icon {
    font-size: 16px !important;
  }

  .refer-link {
    font-size: 12px;
    text-align: right;
    padding-bottom: 8px;
  }

  .w-49 {
    width: 48%;
  }
}

@media screen and (max-width: 1000px) {
  .card-title,
  .card-info,
  .refer-link {
    font-size: 14px;
  }

  .card-icon {
    width: 40px;
    height: 40px;
  }


  .card-details {
    width: 59%;
  }

  .card-info {
    margin-top: -5px;
  }

  .refer-link {
    padding-bottom: 0;
    text-decoration-thickness: 1px;
    padding-right: 3px;
  }

  .w-49 {
    width: 54%;
  }
}

@media screen and (max-width: 399px) {
  .w-49 {
    width: 50%;
  }
}

@media screen and (max-width: 359px) {
  .w-49 {
    width: 46%;
  }

  .refer-card {
    min-width: 140px;
  }

  .card-icon {
    width: 37px;
    height: 37px;
  }

  .refer__details {
    width: 63%
  }

  .quest__details {
    width: 46%;
  }
}
</style>