<template>
  <section class="skeleton__container">
    <div class="line skeleton" :style="styleProps" v-for="i in getNumberOfLine" :key="i"></div>
  </section>
</template>

<script>
export default {
  name: "LineSkeleton",
  props: {
    height: {
      type: String,
    },
    width: {
      type: String,
    },
    mb: {
      type: String,
    },
    numberOfLine: {
      type: String,
      default: "1",
    },
  },
  computed: {
    styleProps() {
      return {
        '--height': this.height ? `${this.height}px` : '13px',
        '--width': this.width ? `${this.width}%` : '100%',
        '--bottom-margin': this.getBottomMargin
      }
    },
    getBottomMargin() {
      if (this.mb && parseInt(this.numberOfLine) > 1) {
        return `${this.mb}px`
      } else if (parseInt(this.numberOfLine) > 1) {
        return `5px`;
      }
      return 0;
    },
    getNumberOfLine() {
      return new Array(parseInt(this.numberOfLine));
    }
  },
};
</script>

<style scoped lang="scss">
@import './skeletonAnimation.scss';

.skeleton__container {
  display: block;
}

.line {
  box-sizing: border-box;
  height: var(--height);
  width: var(--width);
  border-radius: 10px;
  margin-bottom: var(--bottom-margin);
}
</style>
