<template>
  <v-card class="table-container">
    <section class="header-group">
      <p>Your Limits</p>
      <v-icon
          class="close-icon"
          color="#000000"
          size="20"
          @click="$emit('closeTable')"
      >
        mdi-close
      </v-icon>
    </section>
    <hr class="item-divider">
    <ac-limits-fees></ac-limits-fees>

    <!--    todo: will enable this section when api provided by PO-->
    <!-- <section class="filter-group">
      <p>Monthly</p>
      <p>Daily</p>
    </section> -->

    <!-- table -->
    <!-- <table class="table">
      <thead>
        <tr>
          <th class="blank-header"></th>
          <th class="header">Count</th>
          <th class="header">Amount</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <th rowspan="3" scope="rowgroup" class="sub-header">
            <p class="send-money-text">Send Money</p>
        </th>
          <td>
            <div>
              <p>2</p>
              <hr />
            </div>
          </td>

          <td>
            <div>
              <p>৳10,000</p>
              <hr />
            </div>
          </td>
        </tr>
        <tr>
          <td>100 times</td>
          <td>৳200,000.00</td>
        </tr>
      </tbody>

      <tbody>
        <tr>
          <th rowspan="2" scope="rowgroup" class="sub-header">Cash Out</th>
          <td>
            <div>
              <p>2</p>
              <hr />
            </div>
          </td>
          <td>
            <div>
              <p>৳10,000</p>
              <hr />
            </div>
          </td>
        </tr>
        <tr>
          <td>Not Applicable</td>
          <td>Not Applicable</td>
        </tr>
      </tbody>

      <tbody>
        <tr>
          <th rowspan="2" scope="rowgroup" class="sub-header">Cash Out</th>
          <td>
            <div>
              <p>2</p>
              <hr />
            </div>
          </td>
          <td>
            <div>
              <p>৳10,000</p>
              <hr />
            </div>
          </td>
        </tr>
        <tr>
          <td>100 times</td>
          <td>৳200,000.00</td>
        </tr>
      </tbody>

      <tbody>
        <tr>
          <th rowspan="2" scope="rowgroup" class="sub-header">
            Mobile Recharge
          </th>
          <td>
            <div>
              <p>2</p>
              <hr />
            </div>
          </td>
          <td>
            <div>
              <p>৳10,000</p>
              <hr />
            </div>
          </td>
        </tr>
        <tr>
          <td>100 times</td>
          <td>৳200,000.00</td>
        </tr>
      </tbody>
    </table> -->
  </v-card>
</template>

<script>
import AcLimitsFees from '../../pdfViewer/AcLimitsFees.vue';

export default {
  name: "LimitCard",
  components: {AcLimitsFees}
};
</script>

<style scoped>
p {
  margin-bottom: 0 !important;
}

.table-container {
  width: 100%;
}


.header-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #464646;
  font-weight: 500;
  padding: 17px 20px;
}

.close-icon {
  color: #d0021b !important;
  height: 15px;
  width: 15px;
}

.table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border: 1px solid #dddddd;
}

.item-divider {
  margin: 0;
  border: 1px solid #DEDEDE;
  width: 100%;
}

.filter-group {
  display: flex;
  background: #f8f8f8;
  text-align: center;
  margin-bottom: 21px;
}

.filter-group p {
  width: 50%;
  padding: 7px 0;
  margin-bottom: 15px;
  color: #777777;
  font-size: 18px;
}

.filter-group p:first-of-type {
  border-right: 1px solid #dddddd;
  color: #e2136e;
}

td {
  padding: 20px 0;
}

th,
td {
  border-right: 1px solid #dddddd;
}

tbody {
  border-bottom: 1px solid #dddddd;
  box-sizing: border-box;
}

tbody tr:first-of-type td {
  padding-top: 25px !important;
  padding-bottom: 0 !important;
  color: #e2136e;
  font-weight: 500;
}

tbody tr:last-of-type {
  font-weight: 500;
  color: #464644;
}

.header {
  border-bottom: 1px solid #dddddd;
  font-weight: 500;
  color: #777777;
  padding: 7px 0;
}

.blank-header {
  border-bottom: 0;
}

.send-money-text {
  margin-top: -25px;
}

.sub-header {
  font-size: 18px;
  font-weight: 500;
  color: #464646;
  padding-left: 30px;
  text-align: justify;
}

@media screen and (max-width: 600px) {

  .table-container p,
  tbody tr:last-of-type {
    font-size: 13px;
  }

  .filter-group p,
  .sub-header,
  .sub-header .send-money-text {
    font-size: 15px;
  }

  tbody tr th {
    width: 32%;
  }

  .sub-header {
    padding-left: 8px;
    padding-right: 5px;
  }

  th, td {
    letter-spacing: .5px;
  }

  tbody tr:last-of-type td {
    padding-right: 5px;
    padding-left: 5px;
  }
}
</style>