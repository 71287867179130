<template>
  <v-card class="quest-container" elevation="0">
    <section class="loader" v-if="quest.isQuestCardLoading">
      <div class="loader__header">
        <line-skeleton height="13"></line-skeleton>
        <line-skeleton height="13"></line-skeleton>
      </div>

      <div class="loader__quest">
        <image-skeleton class="loader__quest__image" width="85" height="78"></image-skeleton>
        <line-skeleton height="13" width="55" class="loader__quest__line--1"></line-skeleton>
        <line-skeleton height="13" class="loader__quest__line--2"></line-skeleton>
        <line-skeleton height="13" width="40" class="loader__quest__line--3"></line-skeleton>
      </div>
      <line-skeleton class="loader__bar"></line-skeleton>
      <line-skeleton class="loader__info"></line-skeleton>
      <line-skeleton width="60"></line-skeleton>
      <div class="loader__img">
        <image-skeleton class="loader__quest__image" width="50" v-for="i in 4" :key="i"></image-skeleton>
      </div>
      <line-skeleton width="75" class="loader__info--last"></line-skeleton>
    </section>

    <template v-else>
      <section class="quest-header">
        <p>Your Quest</p>
        <p class="quest-link" @click="$router.push('/merchant-quest')">
          View Details
        </p>
      </section>
      <v-divider class="item-divider"></v-divider>
      <template v-if="!quest.isQuestDataUnavailable">
        <section class="quest-details-container">
          <img :src="getLevelIcon" class="quest-info-icon" v-if="quest.level"/>
          <div class="quest-details">
            <p>You can avail</p>
            <p class="cashback">{{ quest.incentivePercantage }} Cashback</p>
            <p class="amount-limit">Upto ৳{{ quest.incentiveMaximum }}</p>
          </div>
        </section>
        <section class="quest-progress" v-if="quest.level">
          <p class="quest-progress-info" v-if="isMerchantLevelLowerThanMaxLevel">
            ৳{{ amountEarnedInCurrentLvl }} / ৳{{ quest.upperLimitOfCurrentLevel }}
          </p>
          <v-progress-linear v-if="isMerchantLevelLowerThanMaxLevel"
                             :value="initialProgress"
                             rounded
                             color="#DF146D"
                             background-color="#E6E6E6"
                             height="10"
                             class="progressbar"
          >
          </v-progress-linear>
          <p class="progress-text" v-if="isCampaignOver">
            The campaign has concluded. Kindly await for the next '<span class="amount">Quest</span>'.
          </p>
          <p class="progress-text" v-if="quest.level && isMerchantLevelLowerThanMaxLevel && !isCampaignOver">
            Process
            <span class="amount">৳{{ quest.needsToEarnForNextLevel }}</span> payment
            amount more from payment links to get to Level {{ quest.level + 1 }}
          </p>
          <p class="progress-text" v-else-if="quest.level && !isMerchantLevelLowerThanMaxLevel && !isCampaignOver">
            <span class="amount">Congrats!</span> You have reached
            <span class="amount">Level {{ quest.level }}</span>
          </p>
        </section>
        <v-divider class="item-divider last-item-divider"></v-divider>
        <quest-campaign-level :quest="quest" v-if="quest.level"></quest-campaign-level>

        <p class="info-msg" v-if="quest.level && isMerchantLevelLowerThanMaxLevel && !isCampaignOver">
          You’re just a few steps away from
          <span class="level-info">Level {{ quest.level + 1 }}</span>
        </p>
      </template>
      <section v-else class="error-section">
        <p>No data found for your query.Please try again later.</p>
      </section>
    </template>
  </v-card>
</template>

<script>
import LevelTwoIcon from "@/assets/image/level-2-icon.svg";
import QuestCampaignLevel from "@/components/dashboard/questCampaign/QuestCampaignLevel.vue";
import LevelOneActiveIcon from "@/assets/image/level-1-active-icon.svg";
import LevelTwoActiveIcon from "@/assets/image/level-2-active-icon.svg";
import LevelThreeActiveIcon from "@/assets/image/level-3-active-icon.svg";
import LevelFourActiveIcon from "@/assets/image/level-4-active-icon.svg";
import LineSkeleton from "cpp-js-util/components/common/skeletonLoader/lineSkeleton";
import ImageSkeleton from "cpp-js-util/components/common/skeletonLoader/imageSkeleton";

export default {
  name: "QuestCampaignCard",
  components: {
    QuestCampaignLevel,
    LineSkeleton,
    ImageSkeleton
  },
  props: ["quest"],
  data() {
    return {
      levelOneActiveIcon: LevelOneActiveIcon,
      levelTwoActiveIcon: LevelTwoActiveIcon,
      levelThreeActiveIcon: LevelThreeActiveIcon,
      levelFourActiveIcon: LevelFourActiveIcon,
      levelTwoIcon: LevelTwoIcon,
      initialProgress: 0,
    };
  },
  mounted() {
    this.startProgressAnimation();
  },
  methods: {
    startProgressAnimation() {
      setInterval(() => {
        if (this.initialProgress < this.getLevelProgressPercentage) {
          this.initialProgress += 1;
        }
      }, 30)
    },
  },
  computed: {
    getLevelIcon() {
      switch (this.quest.level) {
        case 1:
          return this.levelOneActiveIcon;
        case 2:
          return this.levelTwoActiveIcon;
        case 3:
          return this.levelThreeActiveIcon;
        case 4:
          return this.levelFourActiveIcon;
        default:
          return "";
      }
    },
    amountEarnedInCurrentLvl() {
      let needsToEarn = this.quest.needsToEarnForNextLevel === null ? 0 : this.quest.needsToEarnForNextLevel - 1;
      return Math.max(this.quest.upperLimitOfCurrentLevel - needsToEarn, 0);
    },
    getLevelProgressPercentage() {
      if (this.amountEarnedInCurrentLvl) {
        return (this.amountEarnedInCurrentLvl * 100) / this.quest.upperLimitOfCurrentLevel
      }
      return 0;
    },
    isMerchantLevelLowerThanMaxLevel() {
      return this.quest.level < 4;
    },
    isCampaignOver() {
      return this.quest.remainingDays < 0;
    }
  },
};
</script>

<style scoped>

/*loader style start */
.loader__header {
  margin-bottom: 36px;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}

.loader__header section {
  width: 28%;
}

.loader__quest {
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 85px 1fr;
  grid-template-areas:
    "img line1"
    "img line2"
    "img line3";
  column-gap: 25px;
}

.loader__quest__image {
  grid-area: img;
}

.loader__quest__line--1 {
  grid-area: line1;
}

.loader__quest__line--2 {
  grid-area: line2;
  align-self: center;
}

.loader__quest__line--3 {
  grid-area: line3;
  align-self: flex-end;
}

.loader__leader__board__header {
  margin-bottom: 25px;
}

.loader__bar {
  margin-bottom: 22px;
}

.loader__info {
  margin-bottom: 9px;
}

.loader__img {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  padding: 0 13px;
  margin-bottom: 22px;
}

.loader__info--last {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

/*loader style end */
.quest-container {
  padding: 16px 20px;
  box-sizing: border-box;
  border-radius: 4px;
  width: 94.7%;
  max-width: 480px;
  min-width: 345px;
  margin-left: 13px;
}

p {
  margin-bottom: 0;
  font-size: clamp(14px, calc(12px + 0.308vw), 16px);
}

.quest-container >>> .v-progress-linear--absolute {
  top: 0 !important;
}

.quest-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-weight: 500;
  color: #464646;
}

.quest-link {
  font-weight: 400;
  color: #e2136e;
  cursor: pointer;
}

.item-divider {
  margin: 12px -20px;
}

.quest-details-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.error-section p {
  margin-bottom: 0;
  text-align: center;
  margin: 20% 20%;
  font-size: 16px;
  font-weight: 500;
}

.quest-info-icon {
  width: 110px;
  display: inline-block;
  height: 110px;
}

.quest-details {
  width: 72%;
  color: #464646;
  line-height: 1.4;
}

.cashback {
  font-style: normal;
  font-weight: 600;
  font-size: clamp(24px, calc(22px + 0.48vw), 28px);
}

.amount-limit {
  font-size: clamp(14px, calc(12px + 0.462vw), 18px);
}

.quest-progress-info {
  font-size: clamp(14px, calc(12px + 0.462vw), 18px);
  color: #e2136e;
  text-align: right;
}

.progressbar {
  border-radius: 11px;
  margin-top: 8px;
  margin-bottom: 20px;
}

.progressbar .v-progress-linear--bar {
  transition: width .5s;
  animation: progress-animation 2s 1;
}

@keyframes progress-animation {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.progress-text {
  color: #464646;
  font-size: clamp(14px, calc(12px + 0.462vw), 18px);
  line-height: 1.4;
}

.amount,
.level-info {
  color: #e2136e;
}

.last-item-divider {
  margin: 20px 0 12px;
}

.info-msg {
  margin-top: 8px;
  text-align: center;
  font-style: normal;
  font-size: clamp(14px, calc(12px + 0.462vw), 18px);
  color: #464646;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
}

.v-divider {
  max-width: 120%;
  margin-left: -20px;
  margin-right: -20px;
}

.v-divider:last-of-type {
  max-width: unset;
  margin-left: unset;
  margin-right: unset;
}

@media screen and (max-width: 1295px) {
  p {
    font-size: clamp(14px, calc(12px + 0.16vw), 16px);
  }

  .amount-limit,
  .quest-progress-info,
  .progress-text,
  .info-msg {
    font-size: clamp(14px, calc(12px + 0.334vw), 18px);
  }

  .info-msg {
    width: 96%;
  }

  .cashback {
    font-size: clamp(24px, calc(22px + 0.333vw), 28px);
  }

  .quest-info-icon {
    width: 100px;
    height: 100px;
  }
}

@media screen and (max-width: 1124px) {
  p {
    font-size: 12px;
  }

  .amount-limit,
  .quest-progress-info,
  .progress-text,
  .info-msg {
    font-size: 14px;
  }

  .info-msg {
    width: 100%;
  }

  .quest-container {
    margin-left: 8px;
  }
}

@media screen and (max-width: 1000px) {

  .quest-container {
    width: 100%;
    max-width: 425px;
    min-width: unset;
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 19px
  }

  .quest-header p:first-of-type {
    font-size: 14px;
  }

  .quest-link,
  .quest-details,
  .quest-progress-info,
  .progress-text,
  .info-msg {
    font-size: 12px;
  }

  .cashback {
    font-size: 16px;
  }

  .item-divider {
    margin-left: -12px;
    margin-right: -12px;
  }

  .progressbar {
    height: 7px !important;
    margin-bottom: 12px;
  }

  .quest-info-icon {
    height: 87px;
    width: 87px;
  }

  .quest-details {
    width: 70%;
  }

  .item-divider:last-of-type {
    margin-top: 17px;
    margin-bottom: 17px;
  }

  .info-msg {
    margin-bottom: 0;
    margin-top: 5px;
  }
}
</style>