<template>
  <div>
    <referral-card
        @showLeaderBoard="showLeaderBoard"
        :referral="referral"
    ></referral-card>

    <!--    <div class="disclaimer">-->
    <!--      {{ earningDisclaimer }}-->
    <!--    </div>-->

    <v-dialog v-model="isLeaderBoardShown" width="535">
      <v-card class="leaderboard">
        <div class="title">Weekly Referral Report</div>
        <div class="table-wrapper">
          <table>
            <thead>
            <tr>
              <th>Sl</th>
              <th>Merchant</th>
              <th>Rank</th>
              <th>Earning</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, rowIndex) in referral.tableData" :key="rowIndex">
              <td>{{ rowIndex + 1 }}</td>
              <td>{{ row.merchantName }}</td>
              <td>{{ row.referralRank }}</td>
              <td>৳{{ row.earnings }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div class="buttons" style="text-align: right">
          <v-btn
              color="white"
              style="color: #d0021b; margin-right: 8px;"
              elevation="0"
              @click="hideLeaderBoard"
          >Close
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ReferralCard from "@/components/dashboard/ReferralCard.vue";

export default {
  name: "ReferralSummary",
  components: {
    ReferralCard
  },
  props: ["referral"],
  data() {
    return {
      isLeaderBoardShown: false,
    };
  },
  methods: {
    async showLeaderBoard() {
      this.isLeaderBoardShown = true;
    },
    hideLeaderBoard() {
      this.isLeaderBoardShown = false;
    },
  },
};
</script>

<style>
.referral-summary {
  font-family: Roboto;
  /*margin-bottom: 16px;*/
}

.referral-summary .left-column {
  font-family: Roboto;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 7%);
  background-color: #ffffff;
  padding: 11px 20px 9px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  font-size: 22px;
  min-height: 80px;
  flex: auto;
  margin-bottom: 16px;
  min-height: 86px;
}

.referral-summary .left-column .amount-text {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
  background-color: #e2136e;
  margin-left: 8px;
  padding: 6px 11px 6px 11px;
}

.referral-summary .right-column {
  font-family: Roboto;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 7%);
  background-color: #ffffff;
  padding: 11px 20px 9px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: auto;
  font-size: 22px;
  min-height: 80px;
  margin-bottom: 16px;
  min-height: 86px;
}

.referral-summary .leaderboard-div {
  display: flex;
  /*height: 78px;*/
  justify-content: center;
  /*min-width: 285px;*/
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
  background-color: #e2136e;
  cursor: pointer;
  margin-left: 16px;
  padding: 0px 16px 0px 16px;
  margin-bottom: 16px;
  min-height: 86px;
}

.referral-summary .leaderboard-div .link-text {
  font-size: 20px;
  align-self: center;
  color: #ffffff;
  font-weight: 500;
}

.referral-summary .leaderboard-div .drop-down-icon {
  width: 35px;
  height: 35px;
  margin: 0 0 0 0;
  padding: 8.6px 6px 8px;
  opacity: 0.87;
  align-self: center;
}

.disclaimer {
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  color: #95989a;
  margin-bottom: 8px;
  margin-top: -4px;
}

.leaderboard {
  padding: 16px;
}

.leaderboard .title {
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  text-align: center;
  color: #e2136e;
  margin-top: 16px;
  margin-bottom: 16px;
  /*border-spacing: 0;*/
  /*border-collapse: collapse;*/
}

.leaderboard table {
  width: 100%;
}

.leaderboard thead tr {
  background: #e2136e;
  color: white;
}

.leaderboard tbody tr {
  color: #6e6e6e;
  background: rgb(244, 247, 249);
  border-bottom: 1px solid rgb(128, 128, 128);
}

.leaderboard table th {
  font-weight: 500;
}

.leaderboard table td,
.leaderboard table th {
  border: none !important;
  margin: 0;
  text-align: left;
  padding: 4px 8px;
  font-family: Roboto;
}

@media only screen and (max-width: 900px) {
  .referral-summary .leaderboard-div {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 400px) {
  .table-wrapper {
    max-height: 300px;
    overflow: scroll;
  }

  .table-wrapper * {
    font-size: 12px !important;
  }
}
</style>
