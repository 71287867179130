<template>
  <v-card class="payment-link-container" elevation="0">
    <div class="container-header">
      <p class="header-sub-title">Payment Links</p>
      <v-tooltip right color="#3B77D1">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="tooltip-icon" right>
            mdi-help-circle-outline
          </v-icon>
        </template>
        <span>Share links to receive payments</span>
      </v-tooltip>
    </div>
    <v-divider class="item-divider"></v-divider>
    <div class="default-payment-link-container">
      <p class="container-sub-title">Your Default Link</p>
      <div class="btn-group">
        <div class="default-payment-link">
          <v-text-field
              ref="textToCopy"
              :value="paymentLink"
              readonly
              filled
              dense
              solo
              flat
              background-color="#F5F5F5"
              class="default-payment-link-input"
          >
          </v-text-field>
          <p class="payment-link">{{ paymentLink }}</p>
          <button type="button" class="btn-copy" @click="copyPaymentLink">
            <img src="@/assets/image/copy-icon.svg" alt="" class="copy-icon">
            Copy
          </button>
        </div>

        <div class="custom-payment-links">
          <p class="custom-link-title container-sub-title ">Custom Links</p>
          <div class="custom-link-group">
            <v-btn
                @click="$router.push('/payment-links')"
                class="btn-payment-link"
                outlined
                color="#E2136E"
            >
              <img :src="previousIcon" alt="" class="link-icon">
              Previous Links
            </v-btn>
            <v-btn
                class="btn-payment-link"
                outlined
                color="#E2136E"
                @click="handleCreatePaymentLink"
            >
              <img :src="newLinkIcon" alt="" class="link-icon">
              Create New Link
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <create-payment-link
        ref="createPaymentLink"
        :dialog="dialog"
    ></create-payment-link>
  </v-card>
</template>

<script>
import createPaymentLink from "@/components/dashboard/paymentLink/CreatePaymentLink.vue";
import PreviousIcon from "@/assets/image/previous-icon.svg";
import NewLinkIcon from "@/assets/image/new-link-icon.svg";

export default {
  name: "PaymentLinkCard",
  components: {
    createPaymentLink,
  },
  data() {
    return {
      dialog: false,
      renderComponent: false,
      previousIcon: PreviousIcon,
      newLinkIcon: NewLinkIcon
    };
  },
  methods: {
    handleCreatePaymentLink() {
      this.$router.push({ name: "create-payment-link" })
    },
    copyPaymentLink() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.select();
      let copied = document.execCommand("copy");
      if (copied) {
        this.$gtag.event("Default payment link copied", {
          event_category:
              this.$route.name === "dashboard"
                  ? "Dashboard Page"
                  : "Payment Links Page",
        });
        this.$feedback.showShortToast("Link Copied!");
      }
    },
  },
  computed: {
    paymentLink() {
      return this.$store.getters.getPaymentLink;
    },
  },
};
</script>

<style scoped>
.payment-link-container {
  padding: 16px 20px;
  width: 99.5%;
  max-width: 755px;
  border-radius: 4px;
  margin-top: 20px;
  min-width: 579px;
  margin-left: 13px;
  position: relative;
}

.header-sub-title {
  display: inline-block;
  font-weight: 500;
  font-size: clamp(14px, calc(12px + 0.32vw), 16px);
}

.tooltip-icon {
  margin-top: -4px;
  font-size: 22px;
}

.item-divider {
  margin: 0 -20px 16px;
}

.btn-group {
  display: flex;
  justify-content: space-between;
}

.container-sub-title {
  color: #464646;
  margin-bottom: 13px !important;
  font-size: clamp(14px, calc(12px + 0.32vw), 16px);
}

.default-payment-link-container {
  margin-bottom: 25px;
}

.default-payment-link {
  display: flex;
  width: 43%;
  min-width: 232px;
  height: 44px;
}

.payment-link {
  position: absolute;
  top: 37%;
  font-size: 13px;
  color: #ffffff;
  background: #3b77d1;
  margin: 0;
  padding: 6px 11px;
  border-radius: 4px;
  display: none;
}

.default-payment-link-input:hover + .payment-link {
  display: block;
}

.default-payment-link-input:focus + .payment-link {
  display: block;
}

.default-payment-link-input {
  width: 295px !important;
}

.custom-link-title {
  display: none;
}

.custom-payment-links {
  width: 55%;
  min-width: 292px;
}

.custom-link-group {
  display: flex;
  justify-content: space-between;
}

.btn-payment-link {
  width: 48%;
  height: 42px !important;
  min-width: 138px !important;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
}

.default-payment-link + .btn-payment-link {
  margin-right: 17px;
}

.default-payment-link-input >>> .v-input__slot {
  min-height: 42px !important;
  outline: 1px solid #dfdfdf;
}

.default-payment-link-input >>> .primary--text {
  color: green !important;
}

.btn-copy {
  background-color: #E2136E;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-left: -9px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  height: 42px;
  outline: 1px solid #E2136E;
  z-index: 100;
  padding-left: 10px;
  padding-right: 10px;
}

.link-icon {
  margin-right: 2px;
}

.copy-icon {
  margin-right: 7px;
}

.v-divider {
  max-width: 120%;
  margin-left: -20px;
  margin-right: -20px;
}

.v-input--is-focused >>> .v-input__slot {
  outline: 1px solid #e2136e;
}

@media screen and (max-width: 1295px) {
  .header-sub-title,
  .container-sub-title {
    font-size: clamp(14px, calc(12px + 0.16vw), 16px);
  }

  .default-payment-link-input {
    font-size: 14px;
  }

  .tooltip-icon {
    font-size: 20px;
  }
}

@media screen and (max-width: 1130px) {
  .payment-link-container {
    padding: 16px 16px;
  }

  .default-payment-link-input {
    font-size: 12px;
  }

  .default-payment-link >>> .v-input__control {
    height: 60px !important;
  }

  .btn-payment-link {
    width: 47%;
    height: 35px !important;
    font-size: 12px !important;
  }

  .btn-payment-group {
    display: flex;
    justify-content: space-between;
  }

  .tooltip-icon {
    font-size: 18px;
  }

  .btn-copy {
    width: 36%;
    height: 35px !important;
    font-size: 12px;
  }

  .default-payment-link-input >>> .v-input__slot {
    min-height: 35px !important;
  }

  .v-divider {
    margin-left: -16px;
    margin-right: -16px;
  }
}

@media screen and (max-width: 1000px) {
  .payment-link-container {
    width: 100%;
    max-width: 425px;
    min-width: unset;
    flex-wrap: wrap;
    padding: 16px 12px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 17px;
  }

  .default-payment-link-container {
    margin-bottom: unset;
  }

  .default-payment-link {
    margin-bottom: 20px;
  }

  .header-sub-title {
    margin-bottom: 12px;
  }

  .item-divider {
    margin: 0px -12px 16px;
  }

  .custom-link-title {
    display: block;
    margin-bottom: 12px;
  }

  .btn-group,
  .custom-payment-links {
    flex-direction: column;
  }

  .default-payment-link {
    width: 100%;
  }

  .custom-payment-links {
    width: 100%;
  }

  .btn-payment-link {
    width: 48%;
    height: 36px !important;
    padding: 10px 9px;
  }

  .custom-link-group {
    display: flex;
    justify-content: space-between;
  }

  .header-sub-title,
  .btn-copy,
  .default-payment-link-input,
  .btn-payment-link {
    font-size: 14px !important;
  }

  .btn-payment-link {
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  .container-sub-title {
    font-size: 12px;
  }

  .default-payment-link-input >>> .v-input__slot {
    min-height: 42px !important;
  }

  .btn-copy {
    height: 42px !important;
    width: 80px;
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .payment-link {
    top: 25%;
    font-size: 12px;
  }
}

@media screen and (max-width: 359px) {
  .btn-payment-link {
    font-size: 11px !important;
    max-width: 130px !important;
    min-width: unset !important;
  }

  .custom-payment-links {
    min-width: unset;
  }
}
</style>
