<template>
  <v-card class="social-link-container" :class="{'social-link-container-alt':isAltDesignApplicable}" elevation="0">
    <img
        :src="dashboardIcon"
        alt="bkash business dashboard icon"
        class="dashboard-icon"
    />
    <section class="link-container-details" :class="{'link-container-details-alt':isAltDesignApplicable}">
      <h3 class="container-title" :class="{'container-title-alt':isAltDesignApplicable}">Join our Facebook group for
        more updates</h3>
      <v-btn
          color="#3B77D1"
          elevation="0"
          class="white--text btn-social"
          :href="facebookGroupLink"
      >
        <v-icon left class="fb-icon">
          mdi-facebook
        </v-icon>
        Join now
      </v-btn>
    </section>
  </v-card>
</template>

<script>
import DashboardIcon from "@/assets/image/dashboard-icon.png";

export default {
  name: "SocialLinkCard",
  data() {
    return {
      dashboardIcon: DashboardIcon,
      demo: true
    };
  },
  computed: {
    facebookGroupLink() {
      return process.env.VUE_APP_FACEBOOK_GROUP_URL || "";
    },
    isAltDesignApplicable() {
      if (this.$vuetify.breakpoint.width <= 1000) {
        return false;
      }
      return !this.$store.getters.getMerchantOnMerchantQuest;
    }
  },
};
</script>

<style scoped>
.social-link-container {
  margin-top: 20px;
  padding: 28px 20px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99.5%;
  max-width: 755px;
  min-width: 579px;
  margin-left: 13px;
}

.social-link-container-alt {
  padding: 28px 20px;
  border-radius: 4px;
  background: #ffffff;
  width: 94.7%;
  max-width: 480px;
  min-width: 345px;
  margin-left: 13px;
  margin-top: 20px;
}

.link-container-details {
  width: 80%;
}

.link-container-details-alt {
  width: 67%;
}

.container-title {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #464646;
  margin-bottom: 18px;
}

.btn-social {
  padding: 21px 33px !important;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.1;
  border-radius: 4px;
}

.fb-icon {
  font-size: 22px;
}

@media screen and (max-width: 1295px) {
  .link-container-details {
    width: 78%;
  }

  .link-container-details-alt {
    width: 67%;
  }

  .container-title {
    font-size: 22px;
  }

  .btn-social {
    font-size: 16px;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .fb-icon {
    font-size: 20px;
  }
}

@media screen and (max-width: 1124px) {
  .social-link-container-alt {
    margin-left: 8px;
  }

  .link-container-details {
    width: 76%;
  }

  .link-container-details-alt {
    width: 61%;
  }

  .container-title {
    font-size: 20px;
  }

  .container-title-alt {
    font-size: 18px;
  }

  .btn-social {
    font-size: 14px;
    padding-left: 28px !important;
    padding-right: 28px !important;
  }

  .fb-icon {
    font-size: 18px;
  }

  .social-link-container {
    padding-right: 18px;
    padding-left: 18px;
  }

  @media screen and (max-width: 1000px) {
    .social-link-container {
      width: 100%;
      max-width: 425px;
      padding-left: 16px;
      padding-right: 13px;
      min-width: 311px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 19px;
    }

    .container-title {
      font-size: 16px;
    }

    .btn-social {
      font-size: 14px;
    }

    .dashboard-icon {
      width: 95px;
      height: 95px;
    }

    .link-container-details {
      width: 61%;
    }

    .fb-icon {
      font-size: 20px;
    }
  }
}
</style>