<template>
  <div>
    <template>
      <mobile-layout v-if="isMobile"
                     :transactions="transactions"
                     :loading="isLoading"
                     :transactionCardLoading="isTransactionCardLoading"
                     :quest="quest"
                     :referral="referral"
                     ref="mobileLayout"
                     @refresh-request="dataRefreshRequested"
      ></mobile-layout>

      <web-layout v-else
                  :transactions="transactions"
                  :loading="isLoading"
                  :transactionCardLoading="isTransactionCardLoading"
                  :quest="quest"
                  :referral="referral"
                  ref="webLayout"
                  @refresh-request="dataRefreshRequested"
      ></web-layout>
    </template>
    <v-tour name="myTour" :steps="steps" :callbacks="myTourCallbacks">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
              v-if="tour.steps[tour.currentStep]"
              :key="tour.currentStep"
              :finish="tour.finish"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
              :next-step="tour.nextStep"
              :previous-step="tour.previousStep"
              :skip="tour.skip"
              :step="tour.steps[tour.currentStep]"
              :stop="tour.stop"
              style="background-color: #1260CC; color: #ffffff; z-index: 9999999; !important"
          >
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>

</template>

<script>

import Swal from "sweetalert2";
import WebLayout from "@/components/dashboard/WebLayout.vue";
import MobileLayout from "@/components/dashboard/MobileLayout.vue";

export default {
  name: "Dashboard",
  components: {
    WebLayout,
    MobileLayout
  },
  created() {
    this.loadDashboardData();
    this.populateMerchantQuestScoreAndMeta();
    this.getReferralSummaryData();
  },
  methods: {
    stripCountryCode(shortCode) {
      if (shortCode.slice(0, 1) === '+') {
        return shortCode = shortCode.substring(3);
      } else return shortCode;
    },
    getSearchFieldLabel(searchFieldName) {
      if (searchFieldName.length === 0) return "Enter search string";
      return "Enter " + this.searchFieldNameList.find(el => el.value === searchFieldName).label;
    },
    loadDashboardData(initial = true) {
      this.isLoading = true;
      this.isTransactionCardLoading = true;
      this.referral.isDashBoardLoading = true;

      this.$cppClient.post('dashboard', {})
          .then(({data}) => {
            this.isLoading = false;
            if (initial) {
              this.$store.commit('setPaymentLink', data?.paymentLink);
              this.$store.commit('setPGWLogo', data.merchantLogo + "?t=" + new Date().getTime());
              this.$store.commit('setPageBasePath', data.pageBasePath);
              this.$store.commit('setPromotionalBanner', data.promoBanner);
              this.$store.commit('setMerchantPromoBanner', data.bannerList);
              this.$store.commit('setReleaseNote', data.release);
              this.$store.commit('setIsReferralActive', data.referralLeaderBoardEnabled)
            }
            this.transactions = data.trxList;
            this.isTransactionCardLoading = false;
            this.referral.referralEarnings = data.referralEarnings;
            this.referral.referralRank = data.referralRank;
            this.referral.referralRankUpdateDateTime = data.referralRankUpdateDateTime;
            this.referral.isDashBoardLoading = false;
            if (this.isMobile) {
              this.$refs.mobileLayout.notifyDataRefresh(true)
            } else {
              this.$refs.webLayout.notifyDataRefresh(true);
            }
            this.showReleaseNoteDialog();
          })
          .catch(({response}) => {
            this.isLoading = false;
            this.$feedback.hideLoading();
            this.isTransactionCardLoading = false;
            this.referral.isDashBoardLoading = false;
            this.referral.isDashBoardDataUnavailable = true;
            
            this.$feedback.showSubtleErrorToast("Failed to load data. Please Refresh!");
            if (this.isMobile) {
              this.$refs.mobileLayout.notifyDataRefresh(false)
            } else {
              this.$refs.webLayout.notifyDataRefresh(false);
            }
            
            this.logoutOnError();
            this.$feedback.showFailed(response);
          });
    },
    async logoutOnError() {
      try {
        await this.$cppClient.post('logout', { idToken: this.$store.getters.getIdToken });
        this.$store.commit('clearPageState');
        this.$store.commit('logoutUser')
        this.$router.push({name: 'sign-in'});
      } catch {
        this.$store.commit('clearPageState');
        this.$store.commit('logoutUser')
        this.$router.push({name: 'sign-in'});
      }
    },
    dataRefreshRequested() {
      this.loadDashboardData(false);
    },
    showReleaseNoteDialog() {
      let releaseId = localStorage.getItem('releaseId');
      if (this.releaseNote.isPublished && this.releaseNote.featureList && this.releaseNote.featureList.length > 0 && this.releaseNote.releaseId !== releaseId) {
        localStorage.setItem("releaseId", this.releaseNote.releaseId)
        this.$eventBus.$emit('showReleaseNoteDialog');
      }
    },
    toCampaignBannerDetails(redirectionUrl, id) {
      if (redirectionUrl) {
        window.open(redirectionUrl, '_blank');
      } else if (id?.length > 0) {
        let routeData = this.$router.resolve({path: '/campaign-banner-details/?id=' + id});
        window.open(routeData.href, '_blank');
      }
    },

    myCustomFinishStepCallback() {
      this.$gtag.event("Dashboard Tour completed", {
        'event_category': "Dashboard Page"
      });
    },
    async populateMerchantQuestScoreAndMeta() {
      this.quest.isQuestCardLoading = true;
      try {
        let response = await this.$cppClient.post("/campaign/quest/query", {});
        let {
          campaignName,
          incentiveMaximum,
          incentivePercantage,
          needsToEarnForNextLevel,
          level,
          upperLimitOfCurrentLevel,
          lowerLimitOfCurrentLevel,
          remainingDays
        } = response.data;
        this.$store.commit('setMerchantOnMerchantQuest', !!campaignName)
        this.quest.level = level;
        this.quest.incentiveMaximum = incentiveMaximum;
        this.quest.incentivePercantage = incentivePercantage;
        this.quest.needsToEarnForNextLevel = needsToEarnForNextLevel;
        this.quest.upperLimitOfCurrentLevel = upperLimitOfCurrentLevel;
        this.quest.lvlRangeDifference = upperLimitOfCurrentLevel - lowerLimitOfCurrentLevel;
        this.quest.isQuestCardLoading = false;
        this.quest.remainingDays = remainingDays;
      } catch (ex) {
        let {response} = ex;
        this.quest.isQuestCardLoading = false;
        this.quest.isQuestDataUnavailable = true;
        await this.$feedback.showFailed(response);
      }
    },
    async getReferralSummaryData() {
      this.referral.isReferralCardLoading = true
      try {
        let response = await this.$cppClient.post("/referral/summary/get", {});
        this.referral.tableData = response.data.referralSummaryTableResponse.tableData;
        this.referral.totalReferralCount = response.data.totalReferralCount;
        this.referral.lowestRank = response.data.referralSummaryTableResponse.lowestRank;
        this.referral.isReferralCardLoading = false;
        this.referral.leaderboardUpdateDateTime = response.data.referralSummaryTableResponse.leaderboardUpdateDateTime;
      } catch ({response}) {
        this.referral.isReferralCardLoading = false;
        this.referral.isReferralDataUnavailable = true;
        await this.$feedback.showFailed(response);
      }
    },
  },
  data() {
    return {
      searchFieldName: 'trxId',
      searchFieldNameList: [
        {value: "trxId", label: "Transaction ID"},
        {value: "invoiceNumber", label: "Invoice No"},
        {value: "customerPhoneNumber", label: "Customer Wallet"},
      ],
      tokens: [],
      lastSearchParameters: null,
      searchInput: '',
      isLoading: false,
      isTransactionCardLoading: false,
      transactions: [],
      fullPage: true,
      myTourCallbacks: {
        onFinish: this.myCustomFinishStepCallback,
      },
      quest: {
        incentiveMaximum: 0,
        incentivePercantage: "",
        needsToEarnForNextLevel: "",
        level: "",
        upperLimitOfCurrentLevel: "",
        isQuestCardLoading: false,
        isQuestDataUnavailable: false,
        lvlRangeDifference: 0,
        remainingDays: null
      },
      referral: {
        tableData: [],
        totalReferralCount: "",
        isReferralCardLoading: false,
        isReferralDataUnavailable: false,
        referralEarnings: "",
        referralRank: "",
        referralRankUpdateDateTime: "",
        lowestRank: "",
        isDashBoardDataUnavailable: false,
        isDashBoardLoading: false
      },
      steps: [
        {
          target: '#v-step-0',
          header: {
            title: 'Balance',
          },
          params: {
            placement: 'left'
          },
          content: `Check your <strong>account balance</strong> anytime from Business Dashboard simply by clicking this button.`
        },
        {
          target: '#v-step-1',
          header: {
            title: 'Create and share your payment links',
          },
          content: 'Share your default payment link to collect any amount payments from here or you can just copy it and share anywhere you want. You can also create fixed amount payment-links instantly by just entering the amount'
        },
        {
          target: '#v-step-3',
          header: {
            title: 'Transaction Report',
          },
          content: 'See all your transaction details and download as you required. You can also see your account specific insights from here e.g. total cash out amount, total payment received.'
        },
        {
          target: '#v-step-4',
          header: {
            title: 'Payment Links',
          },
          content: 'Manage all your payment links from this page. You can see all the payment links you have created from this page. If you don’t need a specific payment link anymore, you can delete them from this page.'
        },
        {
          target: '#v-step-5',
          header: {
            title: 'Refund',
          },
          content: 'Want to refund your customer? We have got your back. Visit this page, search the transaction and simply do the refund.'
        },
        {
          target: '#v-step-6',
          header: {
            title: 'limits & fees',
          },
          content: 'Get info related to your limits & fees.'
        },
        {
          target: '#v-step-7',
          header: {
            title: 'Account',
          },
          content: 'Click here to change logo, password or view account info.'
        },
        {
          target: '#v-step-8',
          header: {
            title: 'Banner',
          },
          content: 'Check out the latest offers, campaign details from this banner.'
        },
        {
          target: '#v-step-9',
          header: {
            title: 'Now get started!',
          },
          content: 'Enjoy using bKash Business Dashboard!.',
          params: {
            placement: 'right',
          }
        },
      ]
    }
  },
  computed: {
    releaseNote() {
      return this.$store.getters.getReleaseNote;
    },
    isMobile() {
      return this.$vuetify.breakpoint.width <= 1000;
    },
  },
  mounted() {
    this.$gtag.event("landed on dashboard page", {
      'event_category': "Dashboard Page"
    });
    if (this.$route.params.firstTimeLogin && !this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs) {
      this.$route.params.firstTimeLogin = false;
      console.log("firing tour")
      Swal.fire({
        title: '<span>Welcome to <span style="color: #e2136e;">b</span>Kash Business Dashboard!</span>',
        text: "Start tour of your bKash business dashboard",
        reverseButtons: true,
        showDenyButton: true,
        allowOutsideClick: false,
        showConfirmButton: true,
        denyButtonText: 'Skip Tour',
        confirmButtonText: 'Start!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.$gtag.event("Dashboard Tour started", {
            'event_category': "Dashboard Page"
          });
          this.$tours['myTour'].start();
        } else {
          this.$gtag.event("Dashboard Tour skipped", {
            'event_category': "Dashboard Page"
          });
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>

.container {
  max-width: 100%;
  box-sizing: border-box;
}

.fb-refer-btn-block {
  display: none;
}

.search-filter-name {
  flex-wrap: wrap;
  margin-right: 8px;
  flex-grow: 0;
}

.search-icon-btn {
  margin-top: -6px;
  margin-left: 6px;
}

.search-icon-btn * {
  font-size: 24px !important;
}


.dashboard-layout {
  position: relative;
}

.heading-container {
  margin-bottom: 77px;
  flex-direction: row;
}

.item-layout {
  margin-top: 21px;
  margin-bottom: 21px;
}

.top-row {
  align-items: start;
}

.left-column {
  flex-direction: column !important;
  flex-grow: 1;
  align-items: center;
}

.dashboard-heading {
  align-self: center;
}

.carousel-style {
  height: 164px !important;
}

.banner-container {
  width: 50%;

  .merchant-promo-banner {
    width: 100%;
    height: 164px;
    padding: 40.5px 211.1px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
    background-color: var(--white);
    cursor: pointer;
  }
}

.transaction-list {
  margin-top: 21px;
}

@media only screen and (min-width: 1280px) {
  .container {
    max-width: 1300px
  }

}

@media only screen and (max-width: 900px) { // --tab--
  // FIXME Remove if unnecessary
  //.v-application .mt-10 {
  //  margin-top: 20px !important;
  //  padding: 22px;
  //}

  .page-heading {
    margin-top: 14px;
  }

  .banner-container {
    width: 100%;
    margin-bottom: 31px;
  }

  .banner-container .v-image {
    margin: 0;
  }

  .top-row {
    flex-direction: column;
  }

  .dashboard-heading {
    align-self: flex-start !important;
    font-size: 24px;
    display: block !important;
    line-height: 1.4 !important;
  }

  .carousel-style {
    height: 180px !important;
  }

  .merchant-promo-banner {
    height: 180px !important;
    padding: 0 0px !important;
  }

  .left-column {
    width: 100%;
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 900px) { // --tablet--
  .fb-refer-btn-block {
    display: block;
  }

  .fb-group-btn {
    background-image: url('../../assets/image/facebook-group-join.png');
    background-size: contain;
    width: 150px;
    min-height: 50px;
    border-radius: 10px;
    margin-left: 20px;
    box-shadow: none;
  }
}

@media only screen and (max-width: 560px) { // --phone--
  .dashboard-heading {
    margin-top: 14px;
  }

  .merchant-promo-banner {
    height: 110px !important;
  }

  .carousel-style {
    height: 110px !important;
  }

}

@media only screen and (max-width: 380px) { // --smaller phone--
  .merchant-promo-banner {
    height: 96px !important;
  }

  .carousel-style {
    height: 96px !important;
  }
}

@media only screen and (max-width: 330px) { // --even smaller phone--
  .carousel-style {
    height: 80px !important;
  }

  .merchant-promo-banner {
    height: 80px !important;
  }
}
</style>