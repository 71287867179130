<template>
  <v-card id="v-step-8" class="banner-container right-column" elevation="0">
    <v-carousel
        class="right-column carousel-style"
        cycle
        hide-delimiter-background
        show-arrows-on-hover
    >
      <v-carousel-item v-for="(slide, i) in merchantPromoBannerList" :key="i">
        <v-img
            v-if="slide"
            :src="slide.imageUrl"
            class="merchant-promo-banner"
            @click="toCampaignBannerDetails(slide.redirectionUrl, slide.uid)"
            contain
            min-height="57"
        ></v-img>
        <v-img v-else class="merchant-promo-banner"></v-img>
      </v-carousel-item>
    </v-carousel>
  </v-card>
</template>

<script>
export default {
  name: "BannerCard",
  methods: {
    toCampaignBannerDetails(redirectionUrl, id) {
      if (redirectionUrl) {
        window.open(redirectionUrl, "_blank");
      } else if (id?.length > 0) {
        let routeData = this.$router.resolve({
          path: "/campaign-banner-details/?id=" + id,
        });
        window.open(routeData.href, "_blank");
      }
    },
  },
  computed: {
    merchantPromoBannerList() {
      return this.$store.getters.getMerchantPromotionalBanner;
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-style {
  height: 155px !important;
}

.banner-container {
  margin-top: 20px;
  width: 99.5%;
  max-width: 755px;
  min-width: 579px;
  margin-left: 13px;
  height: 135px;

  .merchant-promo-banner {
    width: 755px;
    height: 135px;
    padding: 40.5px 211.1px;
    border-radius: 4px;
    cursor: pointer;

    ::v-deep .v-image__image {
      background-size: 100% 135px;
    }
  }
}

::v-deep .v-btn:not(.v-btn--text):not(.v-btn--outlined).v-btn--active:before {
  opacity: 0;
  display: none;
}

::v-deep .v-btn--round .v-btn__content .mdi-circle {
  font-size: 8px !important;
  color: #d5d5d5;
  padding-top: 15px;
  opacity: 1;
}

::v-deep .v-carousel__controls {
  align-items: flex-end;
}

::v-deep .v-btn--active .v-btn__content .mdi-circle {
  color: #e2136e;
  opacity: 1;
}

::v-deep .v-carousel__controls__item {
  margin: 0;
  width: 15px;
}

::v-deep .v-window__next,
::v-deep .v-window__prev {
  top: calc(50% - 28px);
}

@media screen and (max-width: 1000px) {
  ::v-deep .v-carousel__item {
    height: unset !important;
  }
  ::v-deep .v-btn--round .v-btn__content .mdi-circle {
    font-size: 6px !important;
  }
  ::v-deep .v-window__next,
  ::v-deep .v-window__prev {
    display: none;
  }
  .banner-container {
    width: 100%;
    margin: 16px auto 31px;
    max-width: 425px;
    min-width: auto;
    height: unset;

    .merchant-promo-banner {
      padding: 0 0 !important;
      height: unset;

      ::v-deep .v-image__image {
        background-size: contain;
      }
    }

    .v-image {
      margin: 0;
    }
  }

  .carousel-style {
    height: 90px !important;
    background-color: #f5f5f5;
  }
}

@media screen and (max-width: 414px) {
  .carousel-style {
    height: 82px !important;
  }
}

@media screen and (max-width: 375px) {
  .carousel-style {
    height: 78px !important;
  }
}

@media screen and (max-width: 359px) {
  .carousel-style {
    height: 70px !important;
  }
}
</style>
