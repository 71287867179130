<template>
  <v-dialog
      v-model="showDialog"
      persistent
      width="650"
  >
    <fixed-payment-link-form
        v-if="showPaymentLinkForm"
        ref="fixedPaymentLinkForm"
        @closeDialog="closeDialog"
        @setLink="setLink"
        @hidePaymentLinkForm="hidePaymentLinkForm"
    ></fixed-payment-link-form>
    <fixed-payment-link
        v-else
        :payment-link="link"
        @resetState="closeDialog"
        @setLink="setLink"
    ></fixed-payment-link>
  </v-dialog>
</template>

<script>
import fixedPaymentLink from "./FixedPaymentLink.vue";
import fixedPaymentLinkForm from "./FixedPaymentLinkForm.vue";

export default {
  name: "CreatePaymentLink",
  props: ['changeState', 'dialog'],
  components: {
    fixedPaymentLink,
    fixedPaymentLinkForm
  },
  data() {
    return {
      showPaymentLinkForm: true,
      showDialog: this.dialog,
      link: "",
    }
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
      this.showPaymentLinkForm = true;
      this.$emit("resetState");
      this.reset();
    },
    setLink(val) {
      this.link = val;
    },
    showDialogData() {
      this.showDialog = true;
    },
    hidePaymentLinkForm() {
      this.showPaymentLinkForm = false;
    },
    reset() {
      if (this.$refs.fixedPaymentLinkForm) {
        this.$refs.fixedPaymentLinkForm.resetValues();
      }
    }
  },

}
</script>

