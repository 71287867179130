<template>
  <v-container class="web__container">
    <v-row>
      <v-col class="left-column-group">
        <account-detail-card
            :quest="quest"
            :referral="referral"
        ></account-detail-card>
        <payment-link-card id="v-step-1"></payment-link-card>
        <banner-card></banner-card>
        <recent-transaction
            :transactions="transactions"
            :transactionCardLoading="transactionCardLoading"
            ref="transaction"
            @refresh-request="$emit('refresh-request')"
        ></recent-transaction>
        <custom-page-card v-if="isReferralActive" ></custom-page-card>
        <social-link-card v-if="isMerchantOnMerchantQuest"></social-link-card>
      </v-col>

      <v-col class="right-column-group">
        <!-- <service-card></service-card> -->
        <quest-campaign-card v-if="isMerchantOnMerchantQuest" :quest="quest"></quest-campaign-card>
        <referral-summary :referral="referral" v-if="isReferralActive"
        ></referral-summary>
        <custom-page-card v-if="!isReferralActive" ></custom-page-card>
        <social-link-card v-if="!isMerchantOnMerchantQuest"></social-link-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AccountDetailCard from "@/components/dashboard/accountDetails/AccountDetailCard.vue";
// import ServiceCard from "@/components/dashboard/ServiceCard";
import PaymentLinkCard from "@/components/dashboard/PaymentLinkCard";
import RecentTransaction from "@/components/dashboard/RecentTransaction";
import CustomPageCard from "@/components/dashboard/CustomPageCard";
import SocialLinkCard from "@/components/dashboard/SocialLinkCard";
import QuestCampaignCard from "@/components/dashboard/QuestCampaignCard";
import ReferralSummary from "@/components/dashboard/ReferralSummary";
import BannerCard from "@/components/dashboard/BannerCard";

export default {
  name: "WebLayout",
  components: {
    AccountDetailCard,
    // ServiceCard,
    PaymentLinkCard,
    RecentTransaction,
    CustomPageCard,
    SocialLinkCard,
    QuestCampaignCard,
    ReferralSummary,
    BannerCard,
  },
  props: [
    "transactions",
    "loading",
    "quest",
    "transactionCardLoading",
    "referral"
  ],
  data() {
    return {
      totalReferral: "",
    };
  },
  methods: {
    notifyDataRefresh(wasSuccessful) {
      this.$refs.transaction.notifyDataRefresh(wasSuccessful);
    }
  },
  computed: {
    isMerchantOnMerchantQuest() {
      return this.$store.getters.getMerchantOnMerchantQuest;
    },
    
    isReferralActive() {
      return this.$store.getters.getIsReferralActive;
    },

  }
};
</script>

<style scoped>
.left-column-group {
  padding-right: 6px;
  width: 61%;
}

.right-column-group {
  padding-left: 6px;
  width: 39%;
}

.web__container{
  margin-top: 75px;
}
@media screen and (max-width: 1285px) {
  .left-column-group {
    padding-right: 10px;
  }

  .right-column-group {
    padding-left: 10px;
  }
}
</style>