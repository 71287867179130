<template>
  <div class="account-details-container">
    <account-balance-card id="v-step-0"
                          :class="{'w-50': !isMerchantOnMerchantQuest}"
                          :isMerchantOnMerchantQuest="isMerchantOnMerchantQuest"
    ></account-balance-card>
    <div class="quest-referred-container " :class="{'w-50': !isMerchantOnMerchantQuest}">
      <quest-and-referred-card v-if="isMerchantOnMerchantQuest"
                               :accountDetails="getLatestQuestData"
                               isMerchantOnMerchantQuest="isMerchantOnMerchantQuest"
                               class="quest-card card"
      ></quest-and-referred-card>
      <quest-and-referred-card
          :accountDetails="getLatestReferralData"
          :isMerchantOnMerchantQuest="isMerchantOnMerchantQuest"
          class="refer-card card" :class="{'w-100': !isMerchantOnMerchantQuest}"
      ></quest-and-referred-card>
    </div>
  </div>
</template>

<script>
import AccountBalanceCard from "@/components/dashboard/accountDetails/AccountBalanceCard.vue";
import QuestAndReferredCard from "@/components/dashboard/accountDetails/QuestAndReferredCard.vue";
import QuestIcon from "@/assets/image/quest-icon.svg";
import ReferredIcon from "@/assets/image/referred-icon.svg";

export default {
  name: "AccountDetails",
  components: {AccountBalanceCard, QuestAndReferredCard},
  props: ["quest", "referral"],
  computed: {
    getLatestReferralData() {
      return {
        img: ReferredIcon,
        title: "Referred",
        info: this.referral.totalReferralCount,
        tooltip: "Your total referred count",
        tooltip_position: "bottom",
        isCardLoading: this.referral.isReferralCardLoading,
        isDataUnavailable: this.referral.isReferralDataUnavailable
      };
    },
    getLatestQuestData() {
      return {
        img: QuestIcon,
        title: "Quest",
        info: `Level ${this.quest.level}`,
        tooltip: "Explore your quest levels",
        tooltip_position: "right",
        isCardLoading: this.quest.isQuestCardLoading,
        isDataUnavailable: this.quest.isQuestDataUnavailable
      }
    },
    isMerchantOnMerchantQuest() {
      return this.$store.getters.getMerchantOnMerchantQuest;
    },
    showCard() {
      return this.isMerchantOnMerchantQuest;
    }
  },
};
</script>

<style scoped>
.account-details-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99.5%;
  max-width: 755px;
  min-width: 579px;
  margin-left: 13px;
}

.quest-referred-container {
  display: flex;
  justify-content: space-between;
  width: 58.5%;
}

.card {
  padding: 25px 13px 27px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  height: 114px;
}

.quest-card {
  width: 47%;
  max-width: 210px;
  min-width: 146px;
}

.refer-card {
  width: 49%;
  max-width: 218px;
  min-width: 156px;
}

.w-100 {
  width: 100%;
  max-width: 100%;
}

.w-50 {
  width: 48.5%;
  max-width: 48.5%;

}

@media screen and (max-width: 1295px) {
  .card {
    padding-left: 20px;
    padding-right: 20px;
  }

  .w-100 {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 1180px) {
  .card {
    padding: 22px 16px 24px;
  }

  .w-100 {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .account-details-container {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 425px;
    min-width: unset;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6px;
  }


  .quest-referred-container {
    width: 100%;
    max-width: 425px;
    margin-top: 16px;
  }

  .w-50 {
    width: 100%;
    max-width: 425px;
    margin-top: 17px;
  }

  .refer-card {
    order: 1;
  }

  .quest-card {
    order: 2;
  }

  .quest-card,
  .refer-card {
    padding: 20px 12px;
    width: 48%;
    max-width: 205px;
    height: auto;
  }

  .refer-card {
    padding-right: 6px;
  }

  .w-100 {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 359px) {
  .refer-card {
    min-width: 140px;
  }
}
</style>
