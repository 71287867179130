<template>
  <transaction-mobile-card
      v-if="isMobile"
      :transactions="transactions"
      :transactionCardLoading="transactionCardLoading"
      @refresh-request="refreshClicked"
  ></transaction-mobile-card>
  <v-card
      v-else
      class="transaction-container"
      elevation="0"
  >
    <section class="loader" v-if="transactionCardLoading">
      <div class="loader__header">
        <line-skeleton height="13"></line-skeleton>
        <line-skeleton height="13"></line-skeleton>
      </div>

      <div class="loader__table" v-for="i in 5" :key="i">
        <line-skeleton height="30" numberOfLine="5" mb="18"></line-skeleton>
      </div>
    </section>
    <template v-else>
      <div id="v-step-2" class="item-layout">
        <div class="refresh__container">
          <p class="sub-heading">
            Recent Transactions
          </p>
          <a class="trx__refresh" @click="refreshClicked"
          ><img
              :src="refreshIcon"
              alt=""
              class="refresh__icon"
          />
            <span>Refresh</span></a
          >
        </div>

        <router-link :to="{ name: 'transaction-report' }">
          <span class="router-heading">View All</span>
        </router-link>
      </div>
      <v-divider class="item-divider"></v-divider>
      <v-simple-table>
        <template v-slot:default>
          <thead class="table-heading">
          <tr>
            <th class="text-left table-heading-text">
              Date
            </th>
            <th class="text-center table-heading-text">
              Wallet
            </th>
            <th class="text-center table-heading-text">
              Txn ID
            </th>
            <th class="text-center table-heading-text">
              Type
            </th>
            <th class="text-left table-heading-text">
              Amount
            </th>
          </tr>
          </thead>
          <tbody>
            <tr
                v-for="transaction in transactions"
                :key="makeUniqueRowKey(transaction)"
            >
              <td>{{ transaction.dateTime }}</td>
              <td class="text-center">
                {{ stripCountryCode(transaction.wallet) || "Unknown" }}
              </td>
              <td class="text-center">{{ transaction.trxId }}</td>
              <td class="text-center">
                {{ transaction.trxType || "Unknown Channel" }}
              </td>
              <td class="transaction-amount">{{ formatTransactionAmount(transaction.amount) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <div
          class="no-results-message"
          v-if="!transactionCardLoading && transactions.length === 0"
      >
        No results found for your query
      </div>
    </template>
  </v-card>
</template>

<script>
import TransactionMobileCard from "@/components/dashboard/TransactionMobileCard.vue";
import RefreshIcon from "@/assets/image/refresh-icon.svg";
import LineSkeleton from "cpp-js-util/components/common/skeletonLoader/lineSkeleton";

const CLOCK_UPDATE_INTERVAL = process.env.VUE_APP_CLOCK_UPDATE_INTERVAL;
const AUTO_REFRESH_MIN_THRESHOLD =
    process.env.VUE_APP_AUTO_REFRESH_MIN_THRESHOLD;

export default {
  name: "RecentTransaction",
  props: {
    transactions: Array,
    transactionCardLoading: Boolean,
  },
  components: {
    TransactionMobileCard,
    LineSkeleton,
  },
  data() {
    return {
      clockTime: null,
      lastRefreshed: null,
      lastAttempted: null,
      wasLastRefreshSuccessful: false,
      refreshIcon: RefreshIcon,
    };
  },
  created() {
    this.updateClock();
  },
  methods: {
    makeUniqueRowKey(transaction) {
      return transaction.trxId + "-" + transaction.transactionDateTime;
    },
    stripCountryCode(wallet) {
      if (!wallet) return "";
      return wallet.replace("+880", "0");
    },
    formatTransactionAmount(amount) {
      if (!amount) return "";
      const getSign = amount.charAt(0);
      const getAmount = amount.slice(1);
      return `${getSign} ৳${getAmount}`;
    },
    updateClock() {
      this.clockTime = Date.now();

      if (this.lastRefreshed) {
        let diff =
            this.clockTime - Math.max(this.lastRefreshed, this.lastAttempted);
        if (diff > AUTO_REFRESH_MIN_THRESHOLD) {
          this.refreshClicked();
        }
      }

      setTimeout(() => {
        this.updateClock();
      }, CLOCK_UPDATE_INTERVAL);
    },
    refreshClicked() {
      this.lastAttempted = Date.now();
      this.$emit("refresh-request");
    },
    notifyDataRefresh(wasSuccessful) {
      this.wasLastRefreshSuccessful = wasSuccessful;
      this.lastRefreshed = Date.now();
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.width <= 1000;
    },
  },
};
</script>

<style scoped>

/*loader style start*/
.loader__header {
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}

.loader__header .skeleton__container {
  width: 30%;
}

.loader__header .skeleton__container:last-of-type {
  width: 20%;
}

.loader__table .skeleton__container {
  display: grid;
  grid-template-columns: 1.3fr repeat(2, 1fr) 1.6fr 1fr;
  column-gap: 15px;
}

/*loader style end*/

.transaction-container {
  padding: 16px 20px 25px 20px;
  border-radius: 4px;
  margin-top: 35px;
  width: 99.5%;
  max-width: 755px;
  min-width: 579px;
  margin-left: 13px;
}

.transaction-container >>> .v-progress-linear--absolute {
  top: 0 !important;
}

tbody tr td {
  color: #464646;
  font-size: clamp(12px, calc(12px + 0.16vw), 14px) !important;
}

.item-layout {
  align-items: baseline;
}

.sub-heading {
  font-weight: 500;
  font-size: clamp(14px, calc(12px + 0.32vw), 16px);
  color: #464646;
  line-height: normal !important;
}

.router-heading {
  color: #e2136e;
  font-size: clamp(14px, calc(12px + 0.32vw), 16px);
  font-weight: 500;
}

.item-divider {
  margin: 15px -20px 20px -20px;
}

.table-heading {
  background: #f9f9f9;
  border: none !important;
}

.table-heading-text {
  color: #777777 !important;
  font-size: clamp(12px, calc(12px + 0.32vw), 14px) !important;
  font-weight: normal !important;
  border-bottom: none !important;
}

.table-heading-text:first-of-type {
  padding-left: 20px !important;
}

td:first-of-type {
  padding-left: 20px !important;
}

td {
  border-bottom: 1px solid #eeeeee !important;
}

tbody > tr:last-child > td {
  border-bottom: none !important;
}

.no-results-message {
  text-align: center;
  margin: 8px;
  color: #777777;
}

.refresh-icon {
  font-size: 22px;
  color: #e2136e;
}

.refresh__container {
  display: flex;
  align-items: center;
}

.refresh__container p {
  margin-bottom: 0 !important;
}


.refresh__icon {
  width: 18px;
}

.trx__refresh {
  display: flex;
  margin-left: 10px;
  align-self: flex-start;
  font-size: 14px;
  border-radius: 25px;
  background-color: #ffedf5;
  padding: 2px 6px;
}

.trx__refresh span {
  margin-left: 3px;
}

.v-divider {
  max-width: 120%;
  margin-left: -20px;
  margin-right: -20px;
}

.transaction-amount {
  min-width: 120px;
}

@media screen and (max-width: 1295px) {
  tbody tr td {
    font-size: clamp(12px, calc(10px + 0.16vw), 14px) !important;
  }

  .table-heading-text {
    font-size: clamp(12px, calc(10px + 0.16vw), 14px) !important;
  }

  .sub-heading,
  .router-heading {
    font-size: clamp(14px, calc(12px + 0.16vw), 16px) !important;
  }
}

@media screen and (max-width: 1024px) {
  .transaction-container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .refresh-icon {
    font-size: 18px;
  }

  .v-divider {
    margin-left: -16px;
    margin-right: -16px;
  }
}
</style>
