<template>
  <v-card class="referral-container" elevation="0" :class="removeTopMargin">
    <section class="loader" v-if="isLoading">
      <div class="loader__header">
        <line-skeleton height="13"></line-skeleton>
        <line-skeleton height="13"></line-skeleton>
      </div>
      <div class="rank__loader">
        <image-skeleton
          class="rank__loader__image"
          width="85"
          height="78"
        ></image-skeleton>
        <line-skeleton
          height="13"
          width="40"
          class="rank__loader__line--1"
        ></line-skeleton>
        <line-skeleton
          height="13"
          width="40"
          class="rank__loader__line--2"
        ></line-skeleton>
        <line-skeleton
          height="13"
          class="rank__loader__line--3"
        ></line-skeleton>
      </div>
      <div class="loader__leader__board">
        <line-skeleton
          height="13"
          width="45"
          class="loader__leader__board__header"
        ></line-skeleton>
        <line-skeleton height="13" numberOfLine="6" mb="22"></line-skeleton>
      </div>
    </section>
    <template v-else>
      <div class="referral-container-heading">
        <h3 class="container-sub-title">Referral Leaderboard</h3>
        <p @click="$router.push('/refer')" class="details">
          Start Referring
        </p>
      </div>
      <v-divider class="item-divider"></v-divider>
      <div class="referral-rank">
        <img :src="leaderboardIcon" alt="" />
        <section class="rank-details-container">
          <p>You Ranked</p>
          <p class="rank">{{ getReferralRank }}</p>
          <p class="total-earning">
            Your Earnings
            <span class="earning-amount">{{ getEarningAmount }}</span>
          </p>
        </section>
      </div>
      <v-divider class="item-divider"></v-divider>
      <section
        class="referral-list-container"
        v-if="!referral.isReferralDataUnavailable"
      >
        <h3 class="referral-list-container-sub-heading">Leaderboard</h3>
        <section class="referral-list">
          <div class="referral-list-heading">
            <div class="info-group">
              <p class="sl sl-heading">Sl</p>
              <p class="merchant merchant-heading">Merchant</p>
            </div>
            <p class="earning earning-heading">Earnings</p>
          </div>

          <div
            class="referral-list-item"
            v-for="(tableData, index) in getReferralDataForDashBoard"
            :key="index"
          >
            <div class="info-group">
              <p class="sl sl-no">{{ index + 1 }}</p>
              <p class="merchant merchant-name">{{ tableData.merchantName }}</p>
            </div>
            <p class="earning amount"><span>৳</span>{{ tableData.earnings }}</p>
          </div>
        </section>
      </section>
      <section v-else class="error-section">
        <p>No data found for your query.Please try again later.</p>
      </section>
    </template>
  </v-card>
</template>

<script>
import LeaderboardIcon from "@/assets/image/leaderboard-icon.svg";
import LineSkeleton from "cpp-js-util/components/common/skeletonLoader/lineSkeleton";
import ImageSkeleton from "cpp-js-util/components/common/skeletonLoader/imageSkeleton";

export default {
  name: "ReferralCard",
  props: ["referral"],
  data() {
    return {
      leaderboardIcon: LeaderboardIcon,
    };
  },
  components: {
    ImageSkeleton,
    LineSkeleton,
  },
  computed: {
    getReferralDataForDashBoard() {
      if (this.referral.tableData.length === 0) {
        return [];
      }
      return this.referral.tableData.slice(0, 5);
    },
    getReferralRank() {
      if (
        !this.referral.isDashBoardLoading &&
        !this.referral.isReferralCardLoading
      ) {
        if (
          !this.referral.referralEarnings ||
          (this.referral.isDashBoardDataUnavailable &&
            !this.referral.referralRank)
        ) {
          return "N/A";
        }
        if (
          !this
            .isReferralLeaderboardUpdateDateTimeSameAsMerchantRankUpdateDateTime ||
          !this.referral.referralRank
        ) {
          return "N/A";
        }
        return this.referral.referralRank;
      }
      return "";
    },
    getEarningAmount() {
      if (
        !this.referral.isDashBoardLoading &&
        !this.referral.isReferralCardLoading
      ) {
        if (
          this.referral.isDashBoardDataUnavailable &&
          !this.referral.referralEarnings
        ) {
          return "N/A";
        }
        if (
          !this
            .isReferralLeaderboardUpdateDateTimeSameAsMerchantRankUpdateDateTime ||
          !this.referral.referralEarnings
        ) {
          return `৳0`;
        }
        return `৳${this.referral.referralEarnings}`;
      }
      return "";
    },
    isReferralLeaderboardUpdateDateTimeSameAsMerchantRankUpdateDateTime() {
      return (
        this.referral.leaderboardUpdateDateTime ===
        this.referral.referralRankUpdateDateTime
      );
    },
    isLoading() {
      return this.referral.isReferralCardLoading || this.referral.isDashBoardLoading;
    },
    isMerchantOnMerchantQuest() {
      return this.$store.getters.getMerchantOnMerchantQuest;
    },
    isWeb() {
      return this.$vuetify.breakpoint.width > 1000;
    },
    removeTopMargin() {
      if (!this.isMerchantOnMerchantQuest && this.isWeb) {
        return 'mt-0';
      }
      return 'mt-20';
    }
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0px !important;
  font-size: clamp(14px, calc(12px + 0.308vw), 16px);
}

/*loader style start */
.loader__header {
  margin-bottom: 36px;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}

.loader__header section {
  width: 28%;
}

.rank__loader {
  margin-bottom: 65px;
  display: grid;
  grid-template-columns: 85px 1fr;
  grid-template-areas:
    "img line1"
    "img line2"
    "img line3";
  column-gap: 25px;
}

.rank__loader__image {
  grid-area: img;
}

.rank__loader__line--1 {
  grid-area: line1;
}
.rank__loader__line--2 {
  grid-area: line2;
  align-self: center;
}
.rank__loader__line--3 {
  grid-area: line3;
  align-self: flex-end;
}
.loader__leader__board__header {
  margin-bottom: 25px;
}
/*loader style end */

.mt-0{
  margin-top: 0;
}

.mt-20{
  margin-top: 20px;
}

.referral-container {
  padding: 16px 20px 20px;
  width: 94.7%;
  max-width: 480px;
  min-width: 345px;
  border-radius: 4px;
  margin-top: 20px;
  margin-left: 13px;
}

.referral-container >>> .v-progress-linear--absolute {
  top: 0 !important;
}

.container-sub-title {
  font-size: clamp(14px, calc(12px + 0.308vw), 16px);
  font-weight: 500;
}

.details {
  color: #e2136e;
}

.details:hover {
  cursor: pointer;
}

.referral-container-heading {
  display: flex;
  justify-content: space-between;
}

.referral-rank {
  display: flex;
  padding: 0 12px;
  justify-content: space-between;
  align-items: center;
}

.rank-details-container {
  width: 73%;
  font-size: 18px;
  color: #464646;
  letter-spacing: 0.5px;
}

.rank {
  font-size: clamp(24px, calc(22px + 0.48vw), 28px);
  font-weight: 600;
}

.earning-amount {
  color: #e2136e;
}

.item-divider {
  margin: 12px -20px 20px;
}

.item-divider:last-of-type {
  margin: 24px 0px;
}

.referral-list {
  margin-top: 20px;
}

.referral-list-container {
  padding: 16px;
  background: #f3f3f3;
  border-radius: 4px;
}

.referral-list-container-sub-heading {
  font-size: clamp(14px, calc(12px + 0.308vw), 16px);
  font-weight: 500;
}

.referral-list-heading,
.referral-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  letter-spacing: 0.5px;
}

.info-group {
  width: 65%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sl {
  width: 15%;
}

.sl-heading,
.merchant-heading,
.earning-heading {
  color: #777777;
}

.error-section p {
  margin-bottom: 0;
  text-align: center;
  margin: 20% 20% !important;
  font-size: 16px;
  font-weight: 500;
}

.merchant {
  width: 80%;
  word-break: break-word;
}

.merchant-name {
  line-height: 1.1;
  color: #464646;
}

.earning {
  width: 25%;
}

.sl-no {
  background: #ffdbeb;
  padding: 2px 4px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  color: #e2136e;
  height: 24px;
  width: 24px;
}

.amount {
  color: #e2136e;
}

.referral-list-item:last-child {
  margin-bottom: 0;
}

.v-divider {
  max-width: 120%;
  margin-left: -20px;
  margin-right: -20px;
}

.v-divider:last-of-type {
  max-width: unset;
  margin-left: unset;
  margin-right: unset;
}

@media screen and (max-width: 1295px) {
  p,
  .referral-list-container-sub-heading,
  .container-sub-title {
    font-size: clamp(14px, calc(12px + 0.16vw), 16px);
  }

  .rank {
    font-size: clamp(24px, calc(22px + 0.333vw), 28px);
  }

  .rank-details-container {
    width: 67%;
  }
}

@media screen and (max-width: 1124px) {
  .sl-heading,
  .merchant-heading,
  .earning-heading {
    font-size: 12px;
  }

  .referral-container {
    margin-left: 8px;
  }
}

@media screen and (max-width: 1000px) {
  .referral-container {
    width: 100%;
    max-width: 425px;
    padding-left: 12px;
    padding-right: 12px;
    min-width: 311px;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    margin-top: 19px;
  }

  .item-divider {
    margin-left: -12px;
    margin-right: -12px;
  }

  .container-sub-title,
  .rank-details-container,
  .sl-heading,
  .merchant-heading,
  .earning-heading {
    font-size: 14px;
  }

  .rank {
    font-size: 18px;
  }

  .details {
    font-size: 12px;
  }

  .referral-list-container-sub-heading {
    font-size: 16px;
  }

  img {
    width: 67px;
  }
}
</style>
