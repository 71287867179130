<template>
  <v-card class="page-container" :class="applicableCssClass" elevation="0">
    <custom-page-create-dialog></custom-page-create-dialog>
    <section class="header-group" :class="{'header-group-alt':isAltDesignApplicable}">
      <img :src="customPageIcon" alt="Custom page icon" class="custom-page-icon" :class="{'custom-page-icon-alt':isAltDesignApplicable}"/>
      <div class="header-group-details" :class="{'header-group-details-alt':isAltDesignApplicable}">
        <h2 class="header">
          Build your personal <br v-if="!isAltDesignApplicable"/>business page
        </h2>
        <p class="sub-header">It’s all simple &amp; easy!</p>
      </div>
    </section>
    <section class="cta">
      <v-btn
          outlined
          color="#E2136E"
          class="cta-btn"
          @click="showCustomPageCreateDialog"
      >
        Try Now
      </v-btn>
    </section>
  </v-card>
</template>

<script>
import CustomPageIcon from "@/assets/image/custom-page-icon.svg";
import CustomPageCreateDialog from "@/components/pageManagement/customPage/CustomPageCreateDialog";


export default {
  name: "CustomPageCardVue",
  components: {
    CustomPageCreateDialog
  },
  data() {
    return {
      customPageIcon: CustomPageIcon,
    };
  },
  methods:{
    showCustomPageCreateDialog() {
      this.$eventBus.$emit('showCustomPageCreateDialog');
    },
  },
  computed: {
    isAltDesignApplicable() {
      if (this.$vuetify.breakpoint.width <= 1000) {
        return false;
      }
      return !this.$store.getters.getIsReferralActive;
    },
    isMerchantOnMerchantQuest() {
      return this.$store.getters.getMerchantOnMerchantQuest;
    },
    isWeb() {
      return this.$vuetify.breakpoint.width > 1000;
    },
    isReferralActive() {
      return this.$store.getters.getIsReferralActive;
    },
    shouldRemoveTopMargin() {
      return !this.isMerchantOnMerchantQuest && !this.isReferralActive && this.isWeb;
    },
    applicableCssClass() {
      return {
        'mt-0': this.shouldRemoveTopMargin,
        'page-container-alt': this.isAltDesignApplicable
      }
    }
  },
};
</script>

<style scoped>
.mt-0 {
  margin-top: 0;
}

.page-container {
  margin-top: 20px;
  padding: 20px 20px 20px 0;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 99.5%;
  max-width: 755px;
  min-width: 579px;
  margin-left: 13px;
}

.page-container-alt {
  padding: 28px 20px;
  border-radius: 4px;
  background: #ffffff;
  width: 94.7%;
  max-width: 480px;
  min-width: 345px;
  margin-left: 13px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

p {
  margin-bottom: 0 !important;
}

.header-group {
  width: 71%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
}

.header-group-alt {
  width: 100%;
  flex-direction: column;
  padding: 0 0 16px;
  align-items: flex-start;
}

.header-group-details {
  width: 62%;
}

.header-group-details-alt{
  width: 100%;
}
.header {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #464646;
  line-height: 1.3;
  margin-bottom: 12px;
}

.sub-header {
  font-family: "Roboto";
  font-style: normal;
  font-size: 18px;
  color: #464646;
}

.custom-page-icon {
  display: block;
  width: 145px;
}

.custom-page-icon-alt{
  display: none;
}
.cta {
  width: 28%;
}

.cta-btn {
  padding: 18px 52px !important;
  font-size: 18px;
  font-weight: 400;
  border-radius: 4px;
}

@media screen and (max-width: 1295px) {
  img {
    width: 140px;
  }

  .header-group-details {
    width: 58%;
  }

  .header-group-details-alt{
    width: 100%;
  }
  .header {
    font-size: 22px;
  }

  .sub-header {
    font-size: 16px;
  }

  .header-group {
    padding-left: 25px;
  }

  .header-group-alt {
    padding-left: unset;
  }

  .cta-btn {
    font-size: 16px;
    padding-right: 45px !important;
    padding-left: 45px !important;
  }
}

@media screen and (max-width: 1124px) {
  img {
    width: 135px;
  }

  .header-group-details {
    width: 55%;
  }

  .header-group-details-alt{
    width: 100%;
  }

  .header {
    font-size: 20px;
  }

  .sub-header {
    font-size: 14px;
  }

  .cta-btn {
    font-size: 14px;
    padding-right: 42px !important;
    padding-left: 42px !important;
  }
  .page-container-alt {
    margin-left: 8px;
  }

}
  @media screen and (max-width: 1000px) {
    .page-container {
      width: 100%;
      max-width: 425px;
      padding-left: 16px;
      padding-right: 16px;
      min-width: 311px;
      flex-direction: column;
      align-items: flex-start;
      margin-left: auto;
      margin-right: auto;
      margin-top: 19px;
    }

    .custom-page-icon {
      display: none;
    }

    .header {
      font-size: 16px;
    }

    .sub-header {
      font-size: 14px;
    }

    .cta-btn {
      font-size: 14px;
    }

    .header-group {
      width: 100%;
      flex-direction: column;
      padding: 16px 0;
      align-items: flex-start;
    }

    .header-group br {
      display: none;
    }

    .header {
      width: max-content;
    }
  }
</style>
