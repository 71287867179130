<template>
  <div class="container quest-level-container">
    <div class="quest-header">
      <div class="star-box-container">
        <img
            v-if="!star1"
            class="quest-star quest-star-level-2"
            :src="levelOneInactiveIcon"
        />
        <img
            v-if="star1"
            class="quest-star quest-star-level-2"
            :src="levelOneActiveIcon"
        />
        <div class="progressbar-line">
          <v-progress-linear
              v-model="progressBar1"
              height="7"
              class="progressbar"
          ></v-progress-linear>
        </div>
        <img
            v-if="!star2"
            class="quest-star quest-star-level-2"
            :src="levelTwoInactiveIcon"
        />
        <img
            v-if="star2"
            class="quest-star quest-star-level-2"
            :src="levelTwoActiveIcon"
        />
        <div class="progressbar-line">
          <v-progress-linear
              v-model="progressBar2"
              height="7"
              class="progressbar"
          ></v-progress-linear>
        </div>
        <img
            v-if="!star3"
            class="quest-star quest-star-level-2"
            :src="levelThreeInactiveIcon"
        />
        <img
            v-if="star3"
            class="quest-star quest-star-level-2"
            :src="levelThreeActiveIcon"
        />
        <div class="progressbar-line">
          <v-progress-linear
              v-model="progressBar3"
              height="7"
              class="progressbar"
          ></v-progress-linear>
        </div>
        <img
            v-if="!star4"
            class="quest-star quest-star-level-2"
            :src="levelFourInactiveIcon"
        />
        <img
            v-if="star4"
            class="quest-star quest-star-level-2"
            :src="levelFourActiveIcon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LevelOneActiveIcon from "@/assets/image/level-1-active-icon.svg";
import LevelTwoActiveIcon from "@/assets/image/level-2-active-icon.svg";
import LevelThreeActiveIcon from "@/assets/image/level-3-active-icon.svg";
import LevelFourActiveIcon from "@/assets/image/level-4-active-icon.svg";
import LevelOneInactiveIcon from "@/assets/image/level-1-inactive-icon.svg";
import LevelTwoInactiveIcon from "@/assets/image/level-2-inactive-icon.svg";
import LevelThreeInactiveIcon from "@/assets/image/level-3-inactive-icon.svg";
import LevelFourInactiveIcon from "@/assets/image/level-4-inactive-icon.svg";

export default {
  name: "QuestCampaignLevel",
  props: ["quest"],
  data() {
    return {
      levelOneActiveIcon: LevelOneActiveIcon,
      levelTwoActiveIcon: LevelTwoActiveIcon,
      levelThreeActiveIcon: LevelThreeActiveIcon,
      levelFourActiveIcon: LevelFourActiveIcon,
      levelOneInactiveIcon: LevelOneInactiveIcon,
      levelTwoInactiveIcon: LevelTwoInactiveIcon,
      levelThreeInactiveIcon: LevelThreeInactiveIcon,
      levelFourInactiveIcon: LevelFourInactiveIcon,
      isLoading: false,
      progressBar1: 0,
      progressBar2: 0,
      progressBar3: 0,
      star1: false,
      star2: false,
      star3: false,
      star4: false,
    };
  },
  mounted() {
    this.progressAnimate();
  },
  created() {
  },
  methods: {
    progressAnimate() {
      this.progressBarAnim1();
    },
    progressBarAnim1() {
      if (this.quest.level < 1) return;
      setTimeout(() => {
        this.star1 = true;
        if (this.quest.level === 1) {
          this.progressBar1 = 100 *
              (this.quest.lvlRangeDifference - this.quest.needsToEarnForNextLevel)
              / this.quest.lvlRangeDifference;
        } else {
          this.progressBar1 += 100;
        }
        console.log(this.quest.level);
        this.progressBarAnim2();
      }, 800);
    },
    progressBarAnim2() {
      if (this.quest.level < 2) return;
      setTimeout(() => {
        this.star2 = true;
        if (this.quest.level === 2) {
          this.progressBar2 = 100 *
              (this.quest.lvlRangeDifference - this.quest.needsToEarnForNextLevel)
              / this.quest.lvlRangeDifference;
        } else {
          this.progressBar2 += 100;
        }
        console.log(this.quest.level);
        this.progressBarAnim3();
      }, 800);
    },
    progressBarAnim3() {
      if (this.quest.level < 3) return;
      setTimeout(() => {
        this.star3 = true;
        if (this.quest.level === 3) {
          this.progressBar3 = 100 *
              (this.quest.lvlRangeDifference - this.quest.needsToEarnForNextLevel)
              / this.quest.lvlRangeDifference;
        } else {
          this.progressBar3 += 100;
        }
        setTimeout(() => {
          if (this.quest.level === 4) this.star4 = true;
        }, 600)
      }, 800)
    },
  }
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1185px;
  padding-left: 0;
}

.v-progress-linear {
  overflow: visible;
}

.white-box {
  font-family: Roboto;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 7%);
  background-color: #ffffff;
  padding: 12px;
  margin-bottom: 20px;
}

.white-box--stronger-shadow {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.white-box--title {
  height: 26px;
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #95989a;
  margin-bottom: 16px;
}

.level-label {
  font-family: Roboto;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
}

.level-value {
  font-family: Roboto;
  font-size: 40px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e2136e;
}

.quest-star {
  margin-top: -2px;
  width: 67px;
  height: 67px;
}

.inactive-icon {
  width: 72px;
  height: 54px;
}

.star-box-container {
  display: flex;
  width: 445px;
  align-items: center;
}

.star-box {
  margin-right: 12px;
}

.star-box:last-child {
  margin-right: 0px;
}

.star-label {
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #777;
  margin-top: 6px;
  margin-bottom: 8px;
}

.progressbar-line {
  width: 80px;
  margin: 0 -15px !important;
}

@media screen and (max-width: 1295px) {
  .progressbar-line {
    width: 64px;
  }

  .quest-star {
    width: 64px;
    height: 64px;
  }

}

@media screen and (max-width: 1150px) {
  .progressbar-line {
    width: 55px;
  }

  .quest-star {
    width: 60px;
    height: 60px;
  }

}

@media only screen and (max-width: 600px) {
  .star-box-container {
    display: flex;
    width: auto;
    min-width: 225px;
  }

  .progressbar-line {
    width: 60px;
    margin: 0 -12px !important;

  }

  .progressbar {
    height: 3px !important;
  }

  .quest-level-container {
    padding: 0;
  }
}
</style>