<template>
  <v-card class="card__container">
    <v-icon
        class="close__icon"
        color="#000000"
        @click="$emit('closeDialog')"
    >
      mdi-close
    </v-icon>
    <v-form v-model="isValid" ref="form">
      <div class="create-link-container">
        <span class="container-title">
          Create Payment Link
        </span>
        <v-text-field
            v-model="link.amount"
            outlined
            class="inputNumber input__amount"
            dense
            :rules="rules.inputRules"
            type="number"
            required
        >
          <template v-slot:label>
            <span>Product Price <span class="asterisk">*</span></span>
          </template>
        </v-text-field>
        <span class="note-text">
            Please enter amount based on your category limit [i.e. PRA - ৳2000]
        </span>
        <v-text-field
                v-model="link.description"
                label="Payment Link Name (Optional)"
                class="description input-field"
                outlined
                placeholder="Enter Description"
                dense
                :rules="rules.descriptionRules"
                type="text"
        ></v-text-field>

        <v-radio-group
            v-model="link.linkExpiryType"
            label="Link Validity:"
            column
            class="expiration__container"
        >
          <v-radio
              value="lifetime"
              label="Lifetime"
          ></v-radio>
          <v-radio
              value="custom"
              label="Custom"
          ></v-radio>
        </v-radio-group>

        <div v-if="link.linkExpiryType==='custom'"
             class="date-picker__container"
             :class="{ 'mb__2':!validDatetime ,'mb__12':validDatetime }"
        >
          <date-picker
              class="date-picker__container--item"
              v-model="link.linkExpiryDate"
              :disabled-date="(date) => date < new Date((new Date()).valueOf() - 1000*3600*24)"
              placeholder="Select a date & time"
              type="datetime"
              valueType="format"
              :open.sync="open"
              @change="handleChange"
              format="YYYY-MM-DD hh:mm a"
              time-title-format="hh:mm a"
              :show-second="false"
          >
          </date-picker>
        </div>

        <v-row>
          <div class="error__div">
            <span v-if="!validDatetime">Please select a future time.</span>
          </div>
        </v-row>

        <template class="reference__container" v-for="(reference,index) in link.references" >
            <span :key="index">
              <v-text-field
                  v-model="reference.fieldName"
                  label="Additional Field Name"
                  class="input-field reference__container--item"
                  outlined
                  placeholder="Enter Additional Field Name"
                  dense
                  :rules="rules.referenceFieldRules"
                  type="text"
              ></v-text-field>
              <span class="opinion__container">
                <v-checkbox
                    label="Mandatory field"
                    color="primary"
                    v-model="reference.required"
                ></v-checkbox>
                <v-icon
                    color="primary"
                    @click="removeReferenceField(index)"
                >
                  mdi-close
                </v-icon>
              </span>
            </span>
        </template >

        <v-row class="btn__group">
          <v-btn
              class="btn__add"
              color="#EFEFEF"
              @click="addReferenceField"
              :disabled="checkNumberOfReference"
          >
            <v-icon dark left size="14" class="plus__icon">mdi-plus</v-icon>
            Add Another Field
          </v-btn>

          <v-btn
              color="primary"
              @click="createLink"
              :disabled="!isValid || !validDatetime"
              class="btn__submit"
          >
            Create Link
          </v-btn>
        </v-row>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import {DECIMAL_ABOVE_ZERO_BELOW_ONE_MILLION_RULE} from "cpp-js-util/CppUtil";
import moment from "moment";

export default {
  name: "FixedPaymentLinkForm",
  data() {
    return {
      maxNumberOfReferences: 10,
      showAlert: false,
      open: false,
      link: {
        amount: '',
        description: '',
        linkExpiryType: "lifetime",
        linkExpiryDate: null,
        references: []
      },
      isValid: false,
      rules: {
        referenceFieldRules: [
          v => !!v || 'Field name is required',
          v => !v || v.length <= 20 || 'Field name must not have more than 20 characters'
        ],
        inputRules: [
          (v) => DECIMAL_ABOVE_ZERO_BELOW_ONE_MILLION_RULE.test(v) || "Enter valid amount between 0.01 and 999999.99",
        ],
        descriptionRules: [
          v => !v || v.length <= 20 || 'Description must not have more than 20 characters'
        ]
      },
    }
  },
  methods: {
    resetValues() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.link = {
        amount: '',
        description: '',
        urlPath: "",
        linkExpiryType: "lifetime",
        linkExpiryDate: null,
        references: []
      };
    },
    createLink() {
      const prefix = process.env.VUE_APP_CUSTOMER_PORTAL_URL;
      const format1 = "YYYY-MM-DD HH:mm:ss"
      this.link.linkExpiryDate = moment(this.link.linkExpiryDate).format(format1);
      let linkData = JSON.parse(JSON.stringify((this.link)));

      this.$feedback.showLoading();
      this.$cppClient.post('payment-link/create', linkData)
          .then(({data}) => {
            this.$feedback.hideLoading();
            this.$gtag.event("Fixed Payment Link created", {
              'event_category': (this.$route.name === 'dashboard') ? "Dashboard Page" : "Payment Links Page"
            });
            this.link.urlPath = prefix + data.urlPath;
            this.$emit("setLink", this.link)
            this.$emit("hidePaymentLinkForm");
          })
          .catch(({response}) => {
            this.$feedback.showFailed(response);
          })
    },
    handleChange(value, type) {
      if (type === 'ampm') {
        this.open = false;
      }
    },
    addReferenceField() {
      this.link.references.push({
        fieldName: '',
        required: false,
        value: ''
      })
    },
    removeReferenceField(index) {
      this.link.references.splice(index, 1)
    },

  },
  computed: {
    validDatetime() {
      let current = new Date();
      let selected = new Date(this.link.linkExpiryDate);
      return !(this.link.linkExpiryType === 'custom' && selected < current);
    },
    checkNumberOfReference() {
      return this.link.references.length === this.maxNumberOfReferences;
    },
  }
}
</script>

<style lang="scss" scoped>
$mobileW: 500px;
$mobileSm: 395px;
$mobileM: 565px;

.card__container {
  overflow-x: hidden;
}

.close__icon {
  display: flex;
  justify-content: flex-end;
  width: 98%;
  margin-top: 10px;
}

.create-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .container-title {
    font-size: 34px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;;
    letter-spacing: normal;
    margin-bottom: 17px;
    margin-top: 60px;
    text-align: left;
    color: #464646;
    @media (max-width: $mobileW) {
      font-size: 22px;
      width: 310px !important;
    }
    @media (max-width: $mobileSm) {
      width: 290px !important;
    }
  }

  .note-text {
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #95989a;
    margin-bottom: 15px;
    margin-left: -8px;
    @media (max-width: $mobileSm) {
      width: 259px;
      word-break: break-word;
      margin-left: -25px;
    }
  }

  .input__amount {
    width: 310px !important;
    @media (max-width: $mobileSm) {
      width: 290px !important;
    }
  }

  .input-container {
    width: 298px;
    height: 41.4px;
    border: 0.5px;
  }

  .reference {
    margin-left: -146px;
    margin-bottom: 15px;
  }

  .disable-events {
    pointer-events: none
  }

  .asterisk {
    color: #E2136E;
  }

  .input-field {
    width: 310px !important;
    margin-bottom: 10px;
    @media (max-width: $mobileSm) {
      width: 290px !important;
    }
  }

  .expiration__container {
    width: 309px !important;
    height: 88px !important;
    margin-bottom: 10px;
    margin-top: 0;
    padding-top: 0;
    @media (max-width: $mobileSm) {
      width: 289px !important;
    }
  }

  .description {
    margin-bottom: 5px !important;
  }

  .date-picker__container {
    &--item {
      width: 310px !important;
      @media (max-width: $mobileSm) {
        width: 290px !important;
      }
    }
  }
  .error__div {
    width: 310px !important;
    margin-bottom: 12px;
    font-size: 12px;
    @media (max-width: $mobileSm) {
      width: 290px !important;
    }

    span {
      color: #b71c1c !important;
      padding-left: 10px;
    }
  }

  .mb__2 {
    margin-bottom: 2px;
  }

  .mb__12 {
    margin-bottom: 12px;
  }

  .reference__container {
    display: flex;
    align-items: center;
    &--item{
      margin-bottom: 0 !important;
    }
  }

  .opinion__container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }
  }

  .btn__group{
    width: 310px !important;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 85px;
    @media (max-width: $mobileSm) {
      width: 290px !important;
    }

    .btn__add{
      color: #e2136e;
      width: 310px !important;
      margin-bottom: 15px;
      @media (max-width: $mobileSm) {
        width: 290px !important;
      }

      .plus__icon{
        margin-right: 0;
      }
      &:active{
        color: #FFFFFF;
        background-color: #e2136e !important;
      }
    }
    .v-btn--contained {
      box-shadow: none !important;
    }

    .btn__submit {
      width: 310px !important;
      @media (max-width: $mobileSm) {
        width: 290px !important;
      }
    }
  }
}
</style>
