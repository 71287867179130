<template>
  <v-container>
    <v-row>
      <v-col style="margin: 45px auto;">
        <account-detail-card
            :quest="quest"
            :referral="referral"
        ></account-detail-card>
        <payment-link-card></payment-link-card>
        <banner-card></banner-card>
        <!-- <service-card></service-card> -->
        <quest-campaign-card
            v-if="isMerchantOnMerchantQuest"
            :quest="quest"
        ></quest-campaign-card>
        <recent-transaction
            :transactions="transactions"
            :transactionCardLoading="transactionCardLoading"
            ref="transaction"
            @refresh-request="$emit('refresh-request')"
        ></recent-transaction>
        <referral-summary
            :referral="referral"
            v-if="isReferralActive"
        ></referral-summary>
        <custom-page-card></custom-page-card>
        <social-link-card></social-link-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AccountDetailCard from "@/components/dashboard/accountDetails/AccountDetailCard.vue";
// import ServiceCard from "@/components/dashboard/ServiceCard";
import PaymentLinkCard from "@/components/dashboard/PaymentLinkCard";
import RecentTransaction from "@/components/dashboard/RecentTransaction";
import CustomPageCard from "@/components/dashboard/CustomPageCard";
import SocialLinkCard from "@/components/dashboard/SocialLinkCard";
import QuestCampaignCard from "@/components/dashboard/QuestCampaignCard";
import ReferralSummary from "@/components/dashboard/ReferralSummary";
import BannerCard from "@/components/dashboard/BannerCard";

export default {
  name: "MobileLayout",
  components: {
    AccountDetailCard,
    // ServiceCard,
    PaymentLinkCard,
    RecentTransaction,
    CustomPageCard,
    SocialLinkCard,
    QuestCampaignCard,
    ReferralSummary,
    BannerCard,
  },
  props: [
    "transactions",
    "loading",
    "quest",
    "transactionCardLoading",
    "referral"
  ],
  methods: {
    notifyDataRefresh(wasSuccessful) {
      this.$refs.transaction.notifyDataRefresh(wasSuccessful);
    }
  },
  computed: {
    isMerchantOnMerchantQuest() {
      return this.$store.getters.getMerchantOnMerchantQuest;
    },
    isReferralActive() {
      return this.$store.getters.getIsReferralActive;
    },
  }
};
</script>