<template>
  <v-card class="payment__link__container"
          :class="{ 'h-540': mandatoryReferenceLength }">
    <v-icon
        class="close__icon"
        color="#000000"
        @click="closeDialog"
    >
      mdi-close
    </v-icon>
    <div class="container"
         :class="{ 'h-500': mandatoryReferenceLength }">
    <div class="link-layout-div">
      <div>
        <div style="display: flex; flex-direction: row; margin-bottom: 3px;">
          <span class="container-title">Payment Link: </span>
          <span class="container-title" style="color: #e2136e; margin-left: 10px; margin-right: 5px;">৳</span>
          <span class="container-title" style="color: #95989a">{{ paymentLink.amount }}</span>
        </div>
        <span style="font-size: 20px; color: #95989a; line-height: 1">{{ paymentLink.description }}</span>
      </div>

      <v-list v-if="mandatoryReferences.length > 0">
        <v-subheader class="list__header">
          Fields to be Completed by Customer:
        </v-subheader>
        <v-list-item class="list-item__container"
                     v-for="(reference,index) in mandatoryReferences"
                     :key="index"
        >
          <v-list-item-content class="list-item__container--content">
            <v-list-item-title class="list-item__container--title">
              {{ index + 1 }}. {{ reference.fieldName }}
            </v-list-item-title>

          </v-list-item-content>
        </v-list-item>

      </v-list>

      <v-text-field
          ref="textToCopy"
          id="paymentLink"
          :value="paymentLink.urlPath"
          label="Payment Link"
          outlined
          dense
          height="47"
          class="link-input-text"
          readonly
      ></v-text-field>
      <div class="button-container">
        <v-btn
            @click="copyPaymentLink"
            color=#e2136e
            height=52
            class="link-button">
          Copy
          <v-icon right>
            mdi-content-copy
          </v-icon>
        </v-btn>
        <link-share :paymentLink="paymentLink.urlPath"></link-share>
      </div>
    </div>
    </div>
  </v-card>
</template>

<script>
import LinkShare from "./LinkShare.vue";

export default {
  name: "FixedPaymentLink",
  props: ['paymentLink'],
  data() {
    return {
      counter: 0,
      showDialog: false,
    }
  },
  components: {
    LinkShare,
  },
  computed: {
    linkInfo() {
      return this.$store.getters.getLink;
    },
    mandatoryReferences() {
      return this.paymentLink.references.filter(reference => reference.required);
    },
    mandatoryReferenceLength() {
      return this.mandatoryReferences.length <= 3;
    }
  },
  methods: {
    sharePaymentLink() {
    },
    copyPaymentLink() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector('input')
      textToCopy.select()
      let copied = document.execCommand("copy");
      if (copied) {
        this.$gtag.event("Fixed payment link copied", {
          'event_category': (this.$route.name === 'dashboard') ? "Dashboard Page" : "Payment Links Page"
        });
        this.$feedback.showShortToast("Link Copied!");
      }
    },
    closeDialog() {
      this.$emit('resetState');
    }
  },
}
</script>

<style lang="scss" scoped>
$mobileW: 560px;
$mobileM: 475px;

.payment__link__container {
  overflow-x: hidden;
  height: 585px;
  .close__icon {
    display: flex;
    justify-content: flex-end;
    width: 95%;
    margin-top: 15px;
  }

}

.container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 546px;
  overflow: hidden;
  padding: 0 !important;
}

.h-540{
  height: 540px !important;
}

.h-500{
  height: 500px !important;
}


.link-layout-div {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  margin-top: -39px;
  @media (max-width: $mobileW) {
    width: auto !important;
    height: auto !important;
  }
  @media (max-width: $mobileM) {
    width: 290px !important;
  }


  .container-title {
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
    @media (max-width: $mobileW) {
      font-size: 22px;
    }
  }

  .link-input-text {
    margin-top: 15px;
    margin-bottom: 10px;
    width: 383.8px;

    @media (max-width: $mobileW) {
      width: auto !important;
      margin-right: 0 !important;
      padding: 0;
      margin-top: 10px;
      font-size: inherit;
    }
  }

  .button-container {
    display: flex;
    flex-direction: row;
    width: 383.8px;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $mobileM) {
      width: auto;
    }


    .link-button {
      width: 178px;
      border-radius: 4px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
      color: #FFFFFF;
      @media (max-width: $mobileW) {
        height: 40px !important;
      }
      @media (max-width: $mobileM) {
        width: 130px;
      }
    }
  }

  .link-button {
    width: 383.8px;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.07);
    color: #FFFFFF;
    @media (max-width: $mobileW) {
      height: 40px !important;
    }
    @media (max-width: $mobileM) {
      width: 100%;
    }
  }

  .list__header {
    padding-left: 0;
    height: 30px;
    color: #95989A !important;
  }

  .list-item__container {
    padding-left: 0 !important;
    margin-top: -10px !important;
    min-height: 28px !important;

    &--content {
      padding: 6px 0 !important;
      flex-direction: column !important;
      align-content: flex-start;
    }

    &--title {
      font-size: 14px !important;
      color: #95989A !important;
    }
  }
}

.v-btn {
  font-weight: bold !important;
}
</style>
