<template>
  <div class="pdf-container">
    <vue-pdf-app theme="light" :pdf=pdfUrl :config="config" :page-scale="getPageScaleWidth">
    </vue-pdf-app>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pdfUrl: null,
      title: null,
      isLoading: false,
      config: {
        toolbar: false
      },
    }
  },
  async mounted() {
    this.$gtag.event("landed on limits & fees page", {
      'event_category': "Info Page"
    });

    try {
      this.isLoading = true
      const response = await this.$cppClient.post('/config/get', {});
      const {limitFeesPdfUrl, type} = response.data;
      this.pdfUrl = limitFeesPdfUrl;
      this.title = type;
      this.isLoading = false
    } catch (ex) {
      this.isLoading = false
    }
  },
  computed: {
    getPageScaleWidth() {
      if (this.$vuetify.breakpoint.width <= 1000) {
        return 35;
      }
      return 90;
    }
  }
}
</script>

<style lang="scss" scoped>
.pdf-container {
  height: 80vh;
  width: 100%;

  .pdf-app {
    background-color: #ffffff;
  }

  ::v-deep .page {
    border: none;
    background-clip: unset;
    background-image: none;
    margin: -30px auto -8px auto;

  }

}

#outerContainer {
  z-index: 0 !important;
}

.pdf-app.light {
  --pdf-toolbar-color: white;
  --pdf-toolbar-font-color: grey;
  --pdf-input-color: grey;
}

::v-deep .toolbar {
  z-index: 150 !important;
}

@media screen and (max-width: 1000px) {
  .pdf-container {
    height: 470px !important;

    ::v-deep .page {
      margin-top: -12px;
    }
  }
}
</style>