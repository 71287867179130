<template>
  <div class="image skeleton" :style="styleProps"></div>
</template>

<script>
export default {
  name: "AvatarSkeleton",
  props: ['height', 'width', 'wUnit', 'hUnit'],
  computed: {
    styleProps() {
      return {
        '--height': this.height ? `${this.height}${this.getHeightUnit}` : '48px',
        '--width': this.width ? `${this.width}${this.getHeightUnit}` : '48px',
      }
    },
    getWidthUnit() {
      if (this.wUnit === 'percentage') {
        return '%';
      }
      return 'px';
    },
    getHeightUnit() {
      if (this.hUnit === 'percentage') {
        return '%';
      }
      return 'px';
    }
  },
};
</script>

<style scoped lang="scss">
@import './skeletonAnimation.scss';
.image{
  box-sizing: border-box;
  height: var(--height);
  width: var(--width);
  border-radius: 4px;
}
</style>
