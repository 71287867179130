<template>
  <!--  Mobile UI - start  -->
  <v-card elevation="0" class="transaction-table-mobile">
    <section class="loader" v-if="transactionCardLoading">
      <div class="loader__header">
        <line-skeleton height="13"></line-skeleton>
        <line-skeleton height="13"></line-skeleton>
      </div>
      <line-skeleton height="65" numberOfLine="5" mb="20"></line-skeleton>
    </section>
    <template v-else>
      <div id="v-step-2" class="item-layout">
        <div class="refresh__container">
          <p class="sub-heading">
            Recent Transactions
          </p>
          <a class="trx__refresh" @click="$emit('refresh-request')"
          ><img
              :src="refreshIcon"
              alt=""
              class="refresh__icon"
          />
            <span>Refresh</span></a>
        </div>
        <router-link :to="{ name: 'transaction-report' }">
          <span class="router-heading">See All</span>
        </router-link>
      </div>

      <v-divider class="item-divider"></v-divider>

      <div
          class="no-results-message"
          v-if="!transactionCardLoading && transactions.length === 0"
      >
        No results found for your query
      </div>

      <div
          v-for="(transaction,idx) in transactions"
          :key="makeUniqueRowKey(transaction)"
          class="transaction-list"
      >
        <div class="trx-mob-container">
          <!--  Inactive area - start  -->
          <div
              class="trx-mob-inactive-div"
              v-if="!isActive(transaction, activeIndex)"
          >
            <div class="trxChannel">
              {{ transaction.trxType || "Unknown Channel" }}
            </div>

            <v-expand-transition>
              <div class="simple-row" :class="getClass(idx)">
                <div class="trxId" style="margin-right: 4px;">Trans ID: {{ transaction.trxId }}</div>
                <div class="transactionDateTime">
                  {{ transaction.dateTime }}
                </div>
              </div>
            </v-expand-transition>

            <div class="simple-row">
              <div class="wallet">
                {{ stripCountryCode(transaction.wallet) || "Unknown" }}
              </div>
              <div class="amount">
                {{ formatTransactionAmount(transaction.amount) }}
                <v-card-actions class="action-item">
                  <v-icon @click="displayHiddenItem(idx)" class="icon-chevron" size="18">
                    {{ shouldShowItem(idx) ? 'mdi-chevron-up-circle' : 'mdi-chevron-down-circle' }}
                  </v-icon>
                </v-card-actions>
              </div>
            </div>
          </div>
          <!--  Inactive area - end  -->

        </div>
        <v-divider class="item-divider" :class="{'mt--6': !shouldShowItem(idx)}"></v-divider>
      </div>
    </template>
  </v-card>
  <!--  Mobile UI - end  -->
</template>

<script>
import RefreshIcon from "@/assets/image/refresh-icon.svg";
import LineSkeleton from "cpp-js-util/components/common/skeletonLoader/lineSkeleton";

export default {
  name: "TransactionMobileCard",
  props: {
    transactions: Array,
    transactionCardLoading: Boolean
  },
  components: {
    LineSkeleton,
  },
  methods: {
    formatInfo(info) {
      return info.replace(/,\s/g, ",").split(",");
    },
    makeUniqueRowKey(transaction) {
      return transaction.trxId + "-" + transaction.transactionDateTime;
    },
    moreButtonClicked(transaction) {
      this.activeIndex = this.makeUniqueRowKey(transaction);
    },
    closeButtonClicked() {
      this.activeIndex = null;
    },
    isActive(transaction, activeIndex) {
      return this.makeUniqueRowKey(transaction) === activeIndex;
    },
    stripCountryCode(wallet) {
      if (!wallet) return "";
      return wallet.replace("+880", "0");
    },
    formatTransactionAmount(amount) {
      if (!amount) return "";
      const getSign = amount.charAt(0);
      const getAmount = amount.slice(1);
      return `${getSign} ৳${getAmount}`;
    },
    shouldShowItem(idx) {
      return this[`showItem${idx + 1}`];
    },
    displayHiddenItem(idx) {
      this[`showItem${idx + 1}`] = !this[`showItem${idx + 1}`];
      for (let i = 0; i < this.transactions.length; i++) {
        if (i === idx) {
          continue
        }
        this[`showItem${i + 1}`] = false;
      }
    },
    getClass(id) {
      return this[`showItem${id + 1}`] ? 'show-item' : 'hide-item'
    }
  },
  data() {
    return {
      activeIndex: null,
      showItem1: false,
      showItem2: false,
      showItem3: false,
      showItem4: false,
      showItem5: false,
      refreshIcon: RefreshIcon,
    };
  },
};
</script>

<style lang="scss" scoped>

/*loader style start*/
.loader__header {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
}

.loader__header .skeleton__container {
  width: 45%;
}

.loader__header .skeleton__container:last-of-type {
  width: 20%;
}

/*loader style end*/

.transaction-table-mobile {
  background: white;
  padding: 16px 12px;
  width: 100%;
  max-width: 425px;
  margin: 19px auto 16px;
  border-radius: 4px;

  ::v-deep .v-progress-linear--absolute {
    top: 0 !important;
  }

  .item-layout {
    align-items: baseline;
  }

  .simple-row {
    justify-content: space-between;
    align-items: baseline;
    margin-top: 8px;
  }

  .sub-heading {
    font-weight: 500;
    font-size: 14px;
    color: #464646;
    line-height: normal !important;
  }

  .router-heading {
    color: #e2136e;
    font-size: 12px;
    font-weight: 500;
  }

  .item-divider {
    margin: 16px -12px;

  }

  .transaction-list:last-of-type .item-divider {
    display: none;
  }

  .no-results-message {
    text-align: center;
    margin: 8px;
    color: #777777;
  }

  .trx-mob-container {
    background: #ffffff;

    .trx-mob-inactive-div {
      min-height: 70px;

      .trx-more-button {
        right: -8px;
        top: -8px;
        float: right;
        color: #6e6e6e;
      }
    }

    .trx-mob-active-div {
      min-height: 88px;

      .trx-close-button {
        right: -8px;
        top: -8px;
        float: right;
        color: #6e6e6e;
      }
    }

    .hide-item {
      display: none !important;
    }

    .show-item {
      display: flex !important;
    }

    .action-item {
      display: inline !important;
      padding: 0 0 0 8px !important;
    }
  }

  .mt--6 {
    margin-top: -6px;
  }

  .wallet {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
  }

  .amount {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #464646;
  }

  .trxId {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
    margin-right: 4px;
  }

  .transactionDateTime {
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: right;
    color: #464646;
  }

  .trxChannel {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
  }

  .transactionReferenceLabel {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
  }

  .transactionReferenceContent {
    font-family: Roboto;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #464646;
  }
}

.v-divider {
  max-width: 120%;
  margin-left: -12px;
  margin-right: -12px;
}

.refresh__container {
  display: flex;
  align-items: center;
}

.refresh__container p {
  margin-bottom: 0 !important;
}


.refresh__icon {
  width: 15px;
}

.trx__refresh {
  display: flex;
  margin-left: 10px;
  align-self: flex-start;
  font-size: 12px;
  border-radius: 25px;
  background-color: #ffedf5;
  padding: 2px 6px;
}

.trx__refresh span {
  margin-left: 3px;
}

@keyframes refresh {
  100% {
    transform: rotate(180deg);
  }
}

</style>
