<template>
  <div class="avatar skeleton" :style="styleProps"></div>
</template>

<script>
export default {
  name: "AvatarSkeleton",
  props: ['height', 'width'],
  computed: {
    styleProps() {
      return {
        '--height': this.height ? `${this.height}px` : '48px',
        '--width': this.width ? `${this.width}px` : '48px',
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import './skeletonAnimation.scss';

.avatar {
  box-sizing: border-box;
  height: var(--height);
  width: var(--width);
  border-radius: 100%;
}
</style>
