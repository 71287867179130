<template>
  <v-card class="balance-container" elevation="0">
    <section class="skeleton__loader" v-if="isLoading">
      <avatar-skeleton class="avatar__skeleton"></avatar-skeleton>
      <line-skeleton height="13" width="65" class="line__skeleton--1"></line-skeleton>
      <line-skeleton height="13" class="line__skeleton--2"></line-skeleton>
    </section>
    <template v-else>
      <section class="balance-group">
        <img :src="balanceIcon" alt="Account balance" class="balance-icon"/>
        <div
            class="balance-details"
            :class="{ 'w-70': !isMerchantOnMerchantQuest }"
        >
          <div class="balance__header">
            <p class="balance-text">
              Balance
            </p>
            <a class="balance__refresh" @click="fetchAccountBalance"
            ><img
                :src="refreshIcon"
                alt=""
                class="balance__refresh__icon"
            />
              <span>Refresh</span></a>
          </div>
          <p class="amount-balance">
            {{ isDataUnavailable ? "N/A" : `৳${toCommas(accountBalance)}` }}
          </p>
        </div>
      </section>
      <p class="limit" @click="toggleLimitTable(true)" id="v-step-6">
        Your Limits
      </p>
      <v-dialog v-model="showLimitTable" width="780">
        <limits-card @closeTable="toggleLimitTable(false)"></limits-card>
      </v-dialog>
    </template>
  </v-card>
</template>

<script>
import AccountBalanceIcon from "@/assets/image/balance-icon.svg";
import LimitsCard from "@/components/dashboard/accountDetails/LimitsCard.vue";
import RefreshIcon from "@/assets/image/refresh-icon.svg";
import LineSkeleton from "cpp-js-util/components/common/skeletonLoader/lineSkeleton";
import AvatarSkeleton from "cpp-js-util/components/common/skeletonLoader/avatarSkeleton";

export default {
  name: "AccountBalanceCard",
  created() {
    this.fetchAccountBalance();
  },
  props: ["isMerchantOnMerchantQuest"],
  data() {
    return {
      balanceIcon: AccountBalanceIcon,
      showLimitTable: false,
      accountBalance: "",
      isLoading: false,
      isDataUnavailable: false,
      refreshIcon: RefreshIcon,
    };
  },
  components: {
    LimitsCard,
    LineSkeleton,
    AvatarSkeleton
  },
  methods: {
    toggleLimitTable(showTable) {
      this.showLimitTable = !!showTable;
    },
    async fetchAccountBalance() {
      try {
        this.isLoading = true;
        const response = await this.$cppClient.post("account-balance", {});
        const {currentBalance} = response.data;
        this.accountBalance = currentBalance;
        this.isLoading = false;
      } catch (ex) {
        let {response} = ex;
        this.isLoading = false;
        this.isDataUnavailable = true;
        await this.$feedback.showSubtleErrorToast(
            "Failed to load data. Please Refresh!"
        );
        this.logger("Exception from fetch balance", response);
      }
    },
    toCommas(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0 !important;
}

/*loader start */
.skeleton__loader {
  width: 100%;
  display: grid;
  grid-template-columns: 46px 1fr;
  grid-template-areas: 
  'avatar line1'
  'avatar .'
  'avatar line2';
  column-gap: 15px;
  row-gap: 12px;
}

.avatar__skeleton {
  grid-area: avatar;
}

.line__skeleton--1 {
  grid-area: line1;
}

.line__skeleton--2 {
  grid-area: line2;
}

/*loader end */
.balance-container {
  padding: 23px 13px 23px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  width: 39%;
  max-width: 300px;
  min-width: 208px;
  height: 114px;
}

.balance-container >>> .v-progress-linear--absolute {
  top: 0 !important;
}

>>> .v-dialog {
  overflow: hidden !important;
}

.balance-group {
  width: 65%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balance-icon {
  display: inline-block;
  width: 48px;
  height: 48px;
}

.refresh-icon {
  font-size: 22px;
  color: #e2136e;
  margin-top: -3px;
}

.balance__header {
  display: flex;
  margin-bottom: 16px;
}

.balance__header p {
  margin-bottom: 0 !important;
}

.balance__refresh {
  display: flex;
  margin-left: 10px;
  align-self: flex-start;
  font-size: 14px;
  border-radius: 25px;
  background-color: #ffedf5;
  padding: 2px 6px;
}

.balance__refresh span {
  margin-left: 3px;
}

.balance__refresh__icon {
  width: 18px;
}

.balance-details {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.balance-text {
  font-size: clamp(14px, calc(12px + 0.462vw), 18px);
  font-weight: 500;
  margin-bottom: 12px !important;
  color: #777777;
}

.amount-balance {
  font-size: clamp(16px, calc(12px + 0.8vw), 19px);
  font-weight: 500;
  color: #464646;
}

.limit {
  color: #e2136e;
  font-size: clamp(14px, calc(12px + 0.32vw), 16px);
  font-weight: 400;
  text-decoration: underline;
  align-self: flex-end;
  text-decoration-color: #ff74b1;
  text-decoration-thickness: 2px;
  padding-bottom: 5px;
}

.limit:hover {
  cursor: pointer;
}

@keyframes refresh {
  100% {
    transform: rotate(180deg);
  }
}

@media screen and (max-width: 1295px) {
  .balance-text {
    font-size: clamp(14px, calc(12px + 0.334vw), 18px);
  }

  .amount-balance {
    font-size: clamp(18px, calc(12px + 0.462vw), 20px);
  }

  .limit {
    font-size: clamp(14px, calc(12px + 0.16vw), 16px);
    padding-left: 5px;
  }

  .balance-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .balance-icon {
    height: 44px;
    width: 44px;
  }
}

@media screen and (max-width: 1277px) {
  .amount-balance {
    font-size: 17px;
  }

  .balance-details {
    width: 61%;
  }

  .w-70 {
    width: 70%;
  }

  .balance-container {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media screen and (max-width: 1180px) {
  .balance-text {
    font-size: 14px;
  }

  .amount-balance {
    font-size: 15px;
  }

  .limit {
    font-size: 12px;
    text-align: right;
    padding-bottom: 11px;
  }

  .balance-icon {
    width: 32px;
    height: 32px;
  }

  .balance-container {
    padding-right: 16px;
    padding-left: 16px;
  }

  .balance-details {
    width: 65%;
  }

  .w-70 {
    width: 70%;
  }

  .refresh-icon {
    font-size: 18px;
  }

  .balance__refresh {
    font-size: 12px;
  }

  .balance__refresh__icon {
    width: 15px;
  }
}

@media screen and (max-width: 1000px) {
  .balance-container {
    padding: 20px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    width: 100%;
    max-width: 425px;
    height: auto;
    margin-top: 0 !important;
  }

  .balance-text,
  .amount-balance,
  .limit {
    font-size: 14px;
  }

  .balance-icon {
    width: 40px;
    height: 40px;
  }

  .balance-details {
    padding-left: 5px;
    width: 75%;
  }

  .limit {
    padding-bottom: 0;
    text-decoration-thickness: 1px;
  }
}
</style>
